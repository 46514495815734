<template>
  <div>
    <div>
      <div @click='hiddenBtn' class='ripple_content ripple_success hidden_button '>{{ isHiddenbtn ? '收起' : '展开' }}图表
      </div>

      <SearchCondition :search-condition.sync='searchCondition'></SearchCondition>
      <div v-if="userPermissions.indexOf('team_data_performance') !== -1">
        <PerformanceData :searchCondition='searchCondition' v-hasAuthority="'team_data_performance'"/>
      </div>
    </div>

    <div v-if="userPermissions.indexOf('team_data_trend') !== -1" >
      <el-divider />
      <!--      涨粉情况一览-->
      <trend-monthly-data :isHiddenbtn='isHiddenbtn' :condition='searchCondition' v-hasAuthority="'team_data_trend'"/>
    </div>

    <div v-if="userPermissions.indexOf('team_data_revenue') !== -1">
      <el-divider />
      <!--      营收情况一览-->
      <revenue-monthly-data :isHiddenbtn='isHiddenbtn' :condition='searchCondition'  v-hasAuthority="'team_data_revenue'" />
    </div>

    <div v-if="userPermissions.indexOf('team_data_trend_rank') !== -1" >
      <el-divider />
      <!--      组内季度涨粉排名-->
      <group-trend-rank :condition='searchCondition'  v-hasAuthority="'team_data_trend_rank'"/>
    </div>
    <el-divider />
    <div v-if="userPermissions.indexOf('team_data_revenue_rank') !== -1">
      <!--      组内季度营收排名-->
      <group-revenue-rank :condition='searchCondition'  v-hasAuthority="'team_data_revenue_rank'"/>
    </div>

    <!--  MCN团队奖金-->
    <div  style="padding-top: 5px" v-if="userPermissions.indexOf('team_data_bonus') !== -1" >
      <el-divider />
      <McnBonusView :condition="{year:searchCondition.year,dept_id:searchCondition.group_id}" v-hasAuthority="'team_data_bonus'"/>
    </div>
    <div style='margin-bottom: 2%'>
    </div>
  </div>

</template>

<script>
import PerformanceData from './PerformanceData'
import TrendMonthlyData from '@/pages/team/data/TrendMonthlyData'
import RevenueMonthlyData from '@/pages/team/data/RevenueMonthlyData'
import GroupTrendRank from '@/pages/team/data/GroupTrendRank'
import GroupRevenueRank from '@/pages/team/data/GroupRevenueRank'
import SearchCondition from '../components/SearchCondition'
import RevenueChart from "@/pages/dashboard/revenue/RevenueChart";
import McnBonusView from "@/pages/team/dept/McnBonusView";
import { mapGetters } from 'vuex'

export default {
  name: 'TeamDataReview',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    McnBonusView,
    GroupRevenueRank,
    GroupTrendRank,
    RevenueMonthlyData,
    TrendMonthlyData,
    PerformanceData,
    SearchCondition,
    RevenueChart
  },
  props: {
    condition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  data() {
    return {
      currentGroupId: null,
      searchCondition: { quarter: 1, year: null, group_id: null, artist_id: null },
      isHiddenbtn: true
    }
  },
  watch: {
    condition: {
      immediate: true,
      deep: true,
      handler(val) {
        this.searchCondition.year = val.year
        this.searchCondition.group_id = val.group_id
      }
    }
    // searchCondition: {
    //   immediate: true,
    //   deep: true,
    //   handler(val) {
    //     //
    //     this.searchCondition = val
    //   }
    // }
  },
  methods: {
    searchFn(val) {
      this.searchCondition = val
    },
    hiddenBtn() {
      this.isHiddenbtn = !this.isHiddenbtn
      // return localStorage.getItem(TokenKey)
      localStorage.setItem('isHiddenbtn', this.isHiddenbtn ? '1' : '2')

    }
  },

  mounted() {
    if (localStorage.getItem('isHiddenbtn')) {
      this.isHiddenbtn = localStorage.getItem('isHiddenbtn') === '1' ? true : false
    }

  }
}
</script>

<style scoped>


.hidden_button {
  position: fixed; /* 绝对定位，不管滚动条上下滚动都在相应的位置*/
  z-index: 999;
  bottom: 20px;
  right: 2vw;
}
</style>
