<template>
  <div>
    <el-row>
      <el-col :span='1.5'>
        <span class='title'>组织在职成员一览</span>
        <el-button type='text' :loading='loading' :disabled='loading' @click='getList'
                   icon='el-icon-refresh'></el-button>
        <svg-icon icon-class='adduser' style='cursor: pointer;display: inline-block' @click='addUserBtn'></svg-icon>
      </el-col>
    </el-row>
    <div class='default-table'>
      <el-table row-key='id'
                :row-class-name='rowClassName'
                @row-contextmenu='rowContextmenu' :data='dataList' max-height='600' border>
        <el-table-column type='index' label='序号' width='80' align='center'></el-table-column>
        <el-table-column label='岗位' prop='position' align='center' sortable></el-table-column>
        <el-table-column label='姓名' prop='name' align='center' sortable>
          <template slot-scope='{row}'>
            <el-link type='primary' @click='syncEvent(row.id)' :title='`userid:${row.userid}`'> {{ row.name }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label='入职时间' prop='hired_date' align='center' sortable>
          <template slot-scope='{row}'>
            {{ row.hired_date === '1970-01-01' ? '' : row.hired_date }}
          </template>
        </el-table-column>
        <el-table-column label='调入时间' prop='in_at' align='center' sortable>
        </el-table-column>
        <!--        <el-table-column label='离职时间' prop='leave_date' align='center'></el-table-column>-->
        <el-table-column label='在职时长(天)' prop='duration_alisa' header-align='center'
                         align='right' sortable></el-table-column>
      </el-table>
    </div>
    <div>
      <pagination :hidden='total<pageData.page_size' :total.sync='total' :limit.sync='pageData.page_size'
                  :page.sync='pageData.current_page'
                  @pagination='getList' />
    </div>
    <!--    成员入组-->
    <addUser :dialogTitle='dialogTitle'
             @closeDiag='closeDiag' :visible.sync='showAddMember'
             :groupId='searchCondition.group_id' v-if='showAddMember'></addUser>

    <!--    成员信息-->
    <MemberDetail :visible.sync='dialogVisible' :groupId='searchCondition.group_id' :member-id='currId' @saved='getList'/>
    <!--    <el-dialog title='成员信息实时数据（数据来自钉钉）' width='1000px' :visible.sync='dialogVisible'>-->
    <!--      <div>-->
    <!--        <div v-if='memberInfo.ding_user_info'>-->
    <!--          <EmployeeInfo :groupId='searchCondition.group_id' :user-info='memberInfo.ding_user_info'-->
    <!--                        @closeInfo='dialogVisible=false' :team-member-id='memberInfo.id' @saved='getList' />-->
    <!--        </div>-->
    <!--        <br />-->
    <!--        <div v-if='memberInfo.leave_info'>-->
    <!--          <div>-->
    <!--            <LeaveEmployeeInfo :info='memberInfo.leave_info' />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </el-dialog>-->

    <!--    离职操作-->
    <set-employee-leave :team-member-id='currentRow.id' :visible.sync='dialogDepart' @close='getList' />
    <!--    右键菜单-->
    <Contextmenu :menu-data='menuData' @change='changeCheck' />

  </div>
</template>

<script>
import EmployeeInfo from '@/pages/team/dept/EmployeeInfo'
import LeaveEmployeeInfo from '@/pages/team/dept/LeaveEmployeeInfo'
import addUser from '@/pages/team/components/addUser'
import Contextmenu from '@/pages/team/components/Contextmenu.vue'
import SetEmployeeLeave from '@/pages/team/dept/SetEmployeeLeave'
import MemberDetail from '@/pages/team/dept/MemberDetail'

export default {
  name: 'OnJobEmployeeList',
  components: { MemberDetail, SetEmployeeLeave, LeaveEmployeeInfo, EmployeeInfo, addUser, Contextmenu },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  data() {
    return {
      dataList: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      loading: false,
      dialogVisible: false,
      currId: null,
      memberInfo: {},
      showAddMember: false,
      dialogTitle: '添加成员',
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      currentRow: {},
      dialogDepart: false,
      departTime: ''

    }
  },
  watch: {
    searchCondition: {
      immediate: true,
      deep: true,
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    closeDiag() {
      this.showAddMember = false
      this.getList()
    },
    addUserBtn() {
      this.dialogTitle = '添加成员'
      this.showAddMember = true
    },
    changeCheck(val) {
      if (val === 'del') {
        this.$confirm(
          `确认删除【${this.currentRow.name}】数据吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
          this.deleteData({ ids: [this.currentRow.id] })

        })
      } else if (val === 'refresh') {
        this.refreshUser()
      } else if (val === 'depart') {
        this.dialogDepart = true
      }
    },
    async refreshUser() {
      await this.syncEvent(this.currentRow.id)
      // let data = await this.$api.departTeamRefreshUser({ id: this.currentRow.id })
      // if (data) {
      //   this.$message({
      //     message: '同步成功', type: 'success'
      //   })
      //   await this.getList()
      // }
    },
    async deleteData(delDate) {
      let data = await this.$api.departTeamDelUser(delDate)
      if (data) {
        this.$message({
          message: '删除成功', type: 'success'
        })
        await this.getList()
      }
    },
    async rowContextmenu(row, column, e) {
      // if (this.userPermissions.indexOf('kol-analysis-delete') !== -1) {
      this.currentRow = row
      const menuMinWidth = 105
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      e.preventDefault()//阻止元素发生默认的行为
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      let left = e.clientX - offsetLeft // 15: margin right
      if (left > maxLeft) {
        left = maxLeft
      }
      this.openMenu(row, column, e)
      // }
    },
    openMenu(row, column, e) {
      let index = row.index
      if (index <= this.dataList.length) {
        // if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        this.currentRow = row
        e.preventDefault()//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top // container margin left
        this.menuData = {
          visible: true,
          left: e.clientX - offsetLeft,
          top: e.pageY - offsetTop
        }
        document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
        // }
      }
    },
    //右键菜单：设置是否月考核
    closeMenu() {
      // if (e.target.className.indexOf('contextmenu__item') === -1) {
      this.menuData.visible = false
      document.removeEventListener('click', this.closeMenu)
      // }
    },
    async getList() {
      if (this.searchCondition.group_id && this.searchCondition.year) {
        this.loading = true
        let params = {
          dept_id: this.searchCondition.group_id,
          year: this.searchCondition.year
        }

        // let { list, pages } = await this.$api.getTeamMemberList(Object.assign(params, this.pageData))
        let { list, pages } = await this.$api.getTeamMemberOnJobMembers(Object.assign(params, this.pageData))
        this.dataList = [...list]
        this.total = pages.total
        this.pageData.page_size = pages.page_size || 15
        this.pageData.current_page = pages.current_page
        this.loading = false
      }

    },
    async syncEvent(id) {
      if (id) {
        this.currId = id
        this.dialogVisible = true
      } else
        this.$notify.warning('成员的钉钉ID未获取')
    }
  }
}
</script>

<style scoped>
.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
