<template>
  <div>
    <div class='title-row'>
      <span class='title'>组内季度营收排名 {{ searchCondition.year || '' }}年第{{ searchCondition.quarter + '季度' || '暂未选择季度' }}</span>
      <search-condition :search-condition.sync='searchCondition'></search-condition>
    </div>
    <div class='default-table' style='display: inline-block'>
      <el-table :data='dataList'  :default-sort = "{prop: 'val', order: 'descending'}" border>
        <el-table-column align='center' label='排名' type='index' width='80'></el-table-column>
<!--        <el-table-column align='center' label='排名' type='index' width='100'></el-table-column>-->
        <el-table-column align='center' label='红人昵称' prop='nickname' width='140' sortable></el-table-column>
        <el-table-column align='right' header-align='center' label='季度总营收' prop='val'
                         width='140' sortable></el-table-column>
        <el-table-column align='right' header-align='center' label='上季总营收' prop='last_val'
                         width='140' sortable></el-table-column>
        <el-table-column align='right' header-align='center' label='环比变化' prop='change'
                         width='140' sortable></el-table-column>
      </el-table>
    </div>
    <div style='display: inline-block;text-align: left;vertical-align: top;margin-left: 5px'>
      &nbsp;<i class='el-icon-info'></i>备注：环比变化=当前季度总营收-上一季度总营收
    </div>
  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'
import GroupSelect from '../components/GroupSelect'
import SearchCondition from '../components/SearchCondition'

export default {
  name: 'GroupRevenueRank',
  components: { SearchCondition, ApeTable, GroupSelect },
  props: {
    condition: {
      type: Object,
      default: () => ({ year: null, group_id: null, group_name: '', quarter: 0, release_status: 'Y' })
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        this.initList()
      }
    },
    condition: {
      immediate: true,
      deep: true,
      handler(val) {
        this.searchCondition.year = val.year
        this.searchCondition.group_id = val.group_id
        // this.loadKolOptions()
        this.initList()
      }
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      columns: [
        {
          value: 'rank',
          title: '排名',
          width: 100,
          align: 'center'
        },
        {
          value: 'nickname',
          title: '红人昵称',
          width: 140,
          align: 'center'
        },
        {
          value: 'total',
          title: '季度总营收',
          width: 140,
          align: 'center'
        }, {
          value: 'total_add',
          title: '上季总营收',
          width: 140,
          align: 'center'
        },
        {
          value: 'total_add',
          title: '环比变化',
          width: 140,
          align: 'center'
        }
      ],
      searchCondition: { year: null, group_id: null, quarter: null },
    }
  },
  methods: {
    async initList() {
      this.loadingStatus = true
      if (this.searchCondition && this.searchCondition.year && this.searchCondition.group_id &&this.searchCondition.quarter) {
        let data = await this.$api.getRevenueQuarterArtist(this.searchCondition)
        //revenueDatumList
        this.$nextTick(() => {
          this.dataList = data
          this.loadingStatus = false
        })
      }
    },
    handleQuery() {
      this.dataList = []
      this.initList()
    },
    handleSearch() {
      let SearchData = { ...this.searchCondition }
      SearchData.group_id = this.groupId
      this.SearchData = SearchData
      return SearchData

    }
  },
}
</script>

<style scoped>
.title-row {
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  font-weight: 800;
  float: left;
  line-height: 30px;
  padding-right: 20px;
}
</style>
