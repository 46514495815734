<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-form size='mini' :inline='true'>
          <el-form-item label=''>
            <el-date-picker
              v-model='searchCondition.year'
              type='year'
              format='yyyy年'
              value-format='yyyy'
              placeholder='选择年份' :clearable='true' style='width: 120px!important;'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <DeptCascader ref='refDept' :show-first-group='true' @firstGroup='firstGroup' @selectedDept='selectDept' />
          </el-form-item>
        </el-form>
        <div class='tabs'>
          <el-tabs type='card' v-model='tabIndex' @tab-click='tabClick'>
            <el-tab-pane
              :key='item.name'
              :label='item.title'
              :name='item.name'
              v-for='item in editableTabs'
            >
            </el-tab-pane>
          </el-tabs>
          <div>
            <!--            业务数据-->
            <TeamDataReview :condition='searchCondition' :group-id='currentGroupId'
                            v-if="tabIndex==='team_data' && userPermissions.indexOf('team_data') !== -1"></TeamDataReview>
            <!--            组织分析-->
            <TeamDeptReview :search-condition='searchCondition' :group-id='currentGroupId'
                            v-if="tabIndex==='team_dept'&&userPermissions.indexOf('team_dept') !== -1"></TeamDeptReview>
            <!--            红人分析-->
            <TeamArtistReview ref='refTeamArtistReview' :search-condition='searchCondition'
                              v-if="tabIndex==='team_kol'&&userPermissions.indexOf('team_artist') !== -1"></TeamArtistReview>
          </div>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import TeamDeptReview from './dept/TeamDeptReview'
import TeamDataReview from './data/TeamDataReview'
import TeamArtistReview from './artist/TeamArtistReview'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamIndex',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    PageHeaderLayout, TeamDeptReview, TeamDataReview, TeamArtistReview
  },
  data() {
    return {
      tabIndex: 'team_data',
      editableTabs: [{
        title: '业务数据',
        name: 'team_data',
        content: 'Tab 1 content'
      }, {
        title: '组织分析',
        name: 'team_dept',
        content: 'Tab 2 content'
      }, {
        title: '红人分析',
        name: 'team_kol',
        content: 'Tab 2 content'
      }],
      currentGroupId: null,
      deptInfo: { group_id: null, group_name: null },
      searchCondition: { year: null, group_id: null, group_name: null }
    }
  },
  // watch:{
  //   tabIndex(){
  //     console.log(this.$refs['refTeamArtistReview'])
  //   }
  // },
  created() {
    this.searchCondition.year = '' + (new Date()).getFullYear()
    let query = this.$route.query
    if (query) {
      this.tabIndex = query && ['team_data', 'team_dept', 'team_kol'].indexOf(query.tab) > -1 ? query.tab : 'team_data'
    }

  },
  methods: {
    tabClick() {
      // switch (this.tabIndex) {
      //   case 'team_kol':
      //     this.$nextTick(() => {
      //       if (this.$refs['refTeamArtistReview'])
      //         this.$refs['refTeamArtistReview'].init()
      //     })
      //
      //     break
      // }

      //修改路由
      this.$router.push({ name: 'team', query: { tab: this.tabIndex } })

    },
    firstGroup(info) {
      this.searchCondition.group_id = info.dpt_id
      this.searchCondition.group_name = info.name
    },
    // selectDept(val) {
    //   this.searchCondition.group_id = val && val.length === 2 ? val[val.length - 1] : null
    //   let groupInfo = this.$refs['refDept'].getGroupInfo(this.searchCondition.group_id)
    //   console.log('g',groupInfo)
    // },
    /**
     * 部门选择：val-label
     * @param valArr
     * @param labelArr
     * @returns {Promise<void>}
     */
    selectDept(valArr, labelArr) {
      let group_id = null
      let group_name = null
      if (valArr && valArr.length > 1) {
        let len = valArr.length
        group_id = valArr[len - 1]
        group_name = labelArr[len - 1]

      }
      this.currentGroupId = group_id
      this.deptInfo = { group_id: group_id, group_name: group_name }
      this.searchCondition.group_id = group_id
      this.searchCondition.group_name = group_name
    },
    currentGroup(group) {
      this.currentGroupId = group.dpt_id
      this.deptInfo = { group_id: group.dpt_id, group_name: group.name }
    }
  }
}
</script>

<style scoped>
.dept-info {
  margin-bottom: 10px;
}
</style>
