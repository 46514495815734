<template>
  <div>
    <el-dialog :title='dialogTitle' append-to-body v-bind='$attrs' width='80%' @open='onOpen'
               v-on='$listeners'>
      <el-row :gutter='15'>
        <el-col :span='8'>
          <div class='dpt'>
            <div>
              <el-divider content-position='left'>当前组织架构</el-divider>
              <el-form>
                <el-form-item>
                  <all-dept-select :groupId='groupId' ref='ecp_dept' @handleSelect='selectDept' />
                </el-form-item>
                <!--                <el-divider  content-position="left">离职人员</el-divider>-->
                <!--                <depart-user @members="getDepartList"></depart-user>-->
                <el-form-item label='分组成员'>
                  <div style='display: block;margin-left: 10px'>
                    <el-checkbox v-model='checkAll' :indeterminate='isIndeterminate' style='float: left'
                                 @change='handleCheckAllChange'>
                      全选({{ checkedMembers.length }}/{{ members.length }})
                    </el-checkbox>
                  </div>
                  <el-row>
                    <el-col :span='24'>
                      <el-checkbox-group v-model='checkedMemberIds' @change='handleCheckedCitiesChange'>
                        <el-checkbox v-for='member in members' :key='member.userid' :label='member.userid'>
                          <span style='font-family: syMedium;'> {{ member.name }}</span>
                          <span> / 当前状态: {{ member.is_leave === 'N' ? '在职' : '离职' }}</span>
                          <span>  / 在职时间: {{ member.hired_date || ' -- ' }} ~  {{ member.leave_date || ' 至今' }} </span>
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-form>

            </div>
          </div>
        </el-col>
        <el-col :span='16'>
          <div class='member'>
            <el-row :gutter='15'>
              <el-button :disabled='checkedMembers.length===0' type='primary' @click="batchBtn('entry')">
                批量入组: [ {{
                  checkedMembers.length
                }} ] 人
              </el-button>
              <!--              <el-button :disabled="checkedMembers.length===0 || returnFailList?.length!=0" type="primary"-->
              <!--                         @click="batchBtn('depart')">{{-->
              <!--                  checkedMembers.length-->
              <!--                }}人批量离职-->
              <!--              </el-button>-->
              <!--              <el-button :disabled="checkedMembers.length===0" type="primary" @click="batchBtn('transfer')">{{-->
              <!--                  checkedMembers.length-->
              <!--                }}人批量转组-->
              <!--              </el-button>-->
              <el-divider content-position='left'>已选中</el-divider>
              <el-col :span='12' style='margin-bottom: 20px' :key='item.userid'
                      v-for='item in checkedMembers'>
                <el-card class='box-card'>
                  <svg-icon icon-class='del'
                            style='cursor: pointer;display: block;position:relative; z-index:99;float: right; font-size: 20px'
                            @click='handleClose(item)'></svg-icon>

                  <el-form ref='elForm' :model='item' :rules='rules' label-position='center' label-width='80px'>
                    <el-form-item label='成员名称'>
                      {{ item.name }}
                      <!--                      （<span v-if="item.is_leave==='Y'" style='color: #F56C6C'>{{ item.is_leave_alias }}</span>-->
                      <!--                      <span v-else>{{ item.is_leave_alias }}</span>）-->
                    </el-form-item>
                    <el-form-item label='所属部门'>
                      {{ item.dpt_name }}
                    </el-form-item>
                    <el-form-item label='岗位'>
                      <span>{{ item.position }}</span>
                      <!--                      <el-input style="width: 220px" v-model="item.position" clearable></el-input>-->
                    </el-form-item>
                    <el-form-item label='入组时间'>
                      <el-date-picker
                        v-model='item.in_at'
                        format='yyyy年MM月dd日'
                        placeholder='选择入组时间'
                        type='date' value-format='yyyy-MM-dd'>
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label='入职时间'>
                      <el-date-picker
                        v-model='item.hired_date'
                        format='yyyy年MM月dd日'
                        value-format='yyyy-MM-dd'
                        placeholder='请选择入职时间'
                        type='date'>
                      </el-date-picker>
                    </el-form-item>
                    <!--                    <el-form-item label='离职时间' v-if="item.is_leave==='Y'">-->
                    <!--                      <el-date-picker-->
                    <!--                        disabled-->
                    <!--                        v-model='item.leave_date'-->
                    <!--                        format='yyyy年MM月DD日'-->
                    <!--                        placeholder=''-->
                    <!--                        type='date' value-format='yyyy-M-D'>-->
                    <!--                      </el-date-picker>-->
                    <!--                    </el-form-item>-->
                  </el-form>
                  <span v-show='isReturnData(item)' style='color: red'>
                                 <i class='el-icon-error' style='color: red'></i>     {{ isReturnData(item)?.msg }}
                  </span>
                  <!--                  <el-divider content-position="left">操作</el-divider>-->
                  <!--                  <el-button-group>-->
                  <!--                    <el-popover-->
                  <!--                        v-model="item.leaveVisible"-->
                  <!--                        placement="top"-->
                  <!--                        title="人员离职"-->
                  <!--                        v-if="item.is_leave==='N'"-->
                  <!--                        trigger="click">-->
                  <!--                      <el-date-picker-->
                  <!--                          v-model='item.leave_date'-->
                  <!--                          format='yyyy年MM月DD日'-->
                  <!--                          placeholder='选择离职的日期'-->
                  <!--                          type='date' value-format='yyyy-M-D'>-->
                  <!--                      </el-date-picker>-->
                  <!--                      <div style="float: right">-->
                  <!--                        <el-button size="mini" style="margin: 0 10px" type="text" @click="item.leaveVisible = false">取消-->
                  <!--                        </el-button>-->
                  <!--                        <el-button size="mini" type="primary" @click="leaveBtn">确定</el-button>-->
                  <!--                      </div>-->
                  <!--                      <a slot="reference" title="人员离职">-->
                  <!--                        <el-button type="danger" style="float: left">离职</el-button>-->
                  <!--                      </a>-->
                  <!--                    </el-popover>-->
                  <!--                    <el-popover-->
                  <!--                        v-model="item.visible"-->
                  <!--                        placement="right"-->
                  <!--                        v-if="item.is_leave==='N'"-->
                  <!--                        title="人员调动"-->
                  <!--                        trigger="click">-->
                  <!--                      <div>-->
                  <!--                        调动时间：-->
                  <!--                        <el-date-picker-->
                  <!--                            style="width: 250px"-->
                  <!--                            v-model='item.out_at'-->
                  <!--                            format='yyyy年MM月DD日'-->
                  <!--                            placeholder='选择人员调动时间'-->
                  <!--                            type='date' value-format='yyyy-M-D'>-->
                  <!--                        </el-date-picker>-->
                  <!--                      </div>-->
                  <!--                      <div style="margin: 10px 0">-->
                  <!--                        调动部门：-->
                  <!--                        <team-dept-select ref='ecp_dept'-->
                  <!--                                          style="width:250px;display:inline-block"-->
                  <!--                                          @handleSelect='leaveDept(item,$event)'/>-->
                  <!--                        <div style="display: block;padding-top: 10px;text-align: right">-->
                  <!--                          <el-button size="mini" style="margin: 0 10px" type="text" @click="item.visible = false">取消-->
                  <!--                          </el-button>-->
                  <!--                          <el-button size="mini" type="primary" @click="leaveBtn">确定</el-button>-->
                  <!--                        </div>-->
                  <!--                      </div>-->
                  <!--                      <a slot="reference" title="人员调动">-->
                  <!--                        <el-button type="warning" style="float: left">调动</el-button>-->
                  <!--                      </a>-->
                  <!--                    </el-popover>-->
                  <!--                  </el-button-group>-->
                  <!--                  <el-button type="success" style="float: right" size="medium" @click="saveInfo(item)">保存修改-->
                  <!--                  </el-button>-->
                </el-card>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title='请填写' width='20%' :visible.sync='dialogDepart' v-if='dialogDepart'>
      <el-form size='small' label-width='100px'>
        <el-form-item :label="diagType === 'transfer' ? '转出时间' : '选择时间'">
          <el-date-picker
            @input='daterangeChange'
            @change='batchdepart'
            style='text-align: center;margin: 0 auto'
            v-model='departTime'
            type='date'
            value-format='yyyy-MM-dd'
            placeholder='选择日期'>
          </el-date-picker>
        </el-form-item>
        <el-form-item v-if="diagType==='transfer'" :label="diagType === 'transfer' ? '转出部门' : ''">
          <all-dept-select v-if="diagType==='transfer'"
                           style='width: 70%;display: inline-block' ref='ecp_dept'
                           @handleSelect='transferFun' />
        </el-form-item>
        <el-form-item>
          <el-button style='display: block;text-align: center;' v-if="diagType === 'transfer'" type='primary'
                     @click='transferBtn'>确认转出
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import allDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect.vue'

import DepartUser from '@/pages/team/components/departUser.vue'

export default {
  name: 'CreateMember',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: { allDeptSelect, DepartUser },

  props: {
    dialogTitle: {
      type: String,
      default() {
        return '奖金分配小组成员选择'
      }
    },

    saveUrl: {
      type: String,
      default() {
        return 'addMembersToBonusData'
      }
    },
    groupId: {
      type: String
    }

  },

  data() {
    return {
      loadingCommit: false,
      //userid:钉钉用户ID
      formData: { ym: null, group_id: '', group_name: '', members: [] },
      rules: {
        ym: { required: true },
        group_id: { required: true },
        userid: { required: true }
      },
      props: {
        label: 'name',
        children: 'zones'
      },
      count: 1,
      checkAll: false,
      checkedMembers: [],
      checkedMemberIds: [],
      optMembers: [],
      members: [],
      isIndeterminate: true,
      depart: {
        mode: '',
        options: []
      },
      returnFailList: [],
      dialogDepart: false,
      departTime: '',
      diagType: '',
      transferInfo: '',
      defaultInAt: null
    }
  },
  mounted() {
    let currenDate = new Date()
    this.defaultInAt = currenDate.getFullYear() + '-' + (currenDate.getMonth() + 1) + '-' + currenDate.getDate()
  },
  methods: {
    daterangeChange() {
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    isReturnData(item) {
      let data = this.returnFailList.find((i) => {
        return i.userid == item.userid
      })
      return data
    },
    async getDepartList(list) {
      this.members = list.child
      this.returnFailList = []
    },
    handleDepart() {
    },
    batchBtn(type) {
      this.diagType = type

      if (type === 'entry') {
        // let userIds = this.checkedMembers.map((item) => {
        //   return { userid: item.userid, dept_id: item.dpt_id, nick: item.name, hired_date: this.departTime }
        // })
        // this.dialogDepart = true
        this.batchEntry()
      } else if (type === 'depart') {
        this.dialogDepart = true
      } else if (type === 'transfer') {
        this.dialogDepart = true

      }
    },

    async batchdepart() {
      if (this.diagType === 'depart') {
        let userIds = this.checkedMembers.map((item) => {
          return { userid: item.userid, dept_id: item.dpt_id, nick: item.name, leave_date: this.departTime }
        })

        this.$confirm(
          `确定批量离职【${userIds.length}】人吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
          let data = await this.$api.teamMemberBatchLeave({ items: userIds })
          if (data) {
            if (data.errs.length != 0) {
              this.returnFailList = data.errs
              this.$message.warning(`${data.success}人批量离职成功,${data.fail}人离职失败`)

            } else {
              this.$message.success(`${this.checkedMembers.length}人批量离职成功`)
              this.dialogDepart = false
              setTimeout(() => {
                this.$emit('closeDiag')
              }, 1000)
            }
          }
        })
      } else if (this.diagType === 'entry') {
        // 批量入职
        await this.batchEntry()
      }
    },
    async batchEntry() {
      let items = this.checkedMembers.map((item) => {
        return {
          userid: item.userid,
          dept_id: item.dpt_id,
          dept_name: item.dpt_name,
          name: item.name,
          position: item.position || '未知',
          hired_date: item.hired_date,
          in_at: item.in_at
        }
      })

      this.$confirm(
        `确定批量入职【${items.length}】人吗？`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
        let data = await this.$api.entryTeamMemberUser({ items: items })
        if (data) {
          if (data.errs && data.errs.length != 0) {
            this.returnFailList = data.errs
            this.$message.warning(`${data.success}人批量入职成功,${data.fail}人入职失败`)

          } else {
            this.$message.success(`${this.checkedMembers.length}人批量入职成功`)
            this.dialogDepart = false
            setTimeout(() => {
              this.$emit('closeDiag')
            }, 1000)
          }
        }
      })
    },
    leaveBtn() {
      //离职btn
    },
    leaveDept(item, arr) {
      arr.forEach((item) => {
        item.out_dept_id = item.dpt_id
        item.out_dept_name = item.name
      })
    },
    onOpen() {
      // this.getAInfo()
      this.checkedMembers = []
      this.members = []
      this.isIndeterminate = true
      this.checkAll = false
      this.loadingCommit = false
      this.formData.members = []
    },
    close() {
      this.$emit('update:visible', false)
    },
    encryptMobile(phone) {
      phone = '' + phone
      let reg = /(\d{3})\d{4}(\d{4})/ //正则表达式

      return phone.replace(reg, '$1****$2')
    },
    handleClose(tag) {
      this.checkedMembers.splice(this.checkedMembers.indexOf(tag), 1)

    },
    // eslint-disable-next-line no-unused-vars
    async selectDept(arr) {
      // console.log(arr)
      this.members = []
      if (arr.length > 0) {
        let dept = arr[arr.length - 1]
        this.formData.group_id = dept.dpt_id
        this.formData.group_name = dept.name

        let dept_group = await this.getGroupMembers(dept)
        this.members = [...dept_group.members]
      }
    },
    async getGroupMembers(dept) {
      let members = []
      let searchCondition = { page_size: 100, dpt_id: dept.dpt_id, current_page: 1 }
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      members = [...members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        members = [...members, ...list]
      }
      return { dpt_id: dept.dpt_id, name: dept.name, members: members }
    },
    selectCurrentDept(valArr, labelArr) {
      //加载小组成员
      // this.loadMembers({dpt_id:valArr})
      let group_id = null
      let group_name = null
      let dept_id = null
      let dept_name = null

      if (valArr && valArr.length > 0) {
        let len = valArr.length
        group_id = valArr[len - 1]
        group_name = labelArr[len - 1]
        dept_id = valArr[len - 2]
        dept_name = labelArr[len - 2]
        this.formData.group_id = group_id
        this.formData.group_name = group_name
        this.formData.dept_id = dept_id
        this.formData.dept_name = dept_name
      }
    },
    async loadMembers(searchCondition) {
      this.members = []
      searchCondition.page_size = 50
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      this.members = [...this.members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }
    },
    handleCheckAllChange(val) {
      this.checkedMemberIds = val ? this.members.map((item) => {
        return item.userid
      }) : []


      this.checkedMembers = this.members.map((item) => {
        item.hired_date = item.hired_date === '-' ? null : item.hired_date
        item.in_at = this.defaultInAt
        // Fix 入组时间无法修改问题
        return { ...item }
      })

      this.returnFailList = []

      this.isIndeterminate = false

    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.members.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
      if (value.length != 0) {
        // 成员初始化数据格式化
        this.checkedMembers = value.map((userid) => {
          let item = this.members.find(item => item.userid === userid)
          item.hired_date = item.hired_date === '-' ? null : item.hired_date
          item.in_at = this.defaultInAt
          // Fix 入组时间无法修改问题
          // return item
          return { ...item }
        })
      }
    },
    transferFun(val) {

      this.transferInfo = val[val.length - 1].dpt_id
    },
    transferBtn() {
      let userIds = this.checkedMembers.map((item) => {
        return {
          userid: item.userid,
          out_dept_id: item.dpt_id,
          out_dept_name: item.dpt_name,
          nick: item.name,
          out_at: this.departTime,
          dept_id: this.transferInfo
        }
      })
      if (this.departTime && this.transferInfo) {
        this.$confirm(
          `确认批量转组【${this.userIds.length}】人吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
          this.dialogDepart = false
          let data = await this.$api.departTeamTransferUser({ items: userIds })
          if (data) {
            this.$message({
              message: '转组成功', type: 'success'
            })
          }
          this.$emit('closeDiag')
        })
      } else {
        this.$message({
          message: '请填写信息'
        })
      }
    }
  }
}
</script>

<style scoped>
.dpt {
  min-height: 400px;
  height: 500px;
  max-height: 800px;
  overflow-y: scroll;
  border: #ff3176 1px solid;
  padding: 20px;
  margin-bottom: 20px;
}

.member {
  min-height: 400px;
  height: 500px;
  max-height: 800px;
  overflow-y: scroll;
  border: #ff3176 1px solid;
  padding: 20px;
  margin-bottom: 20px;
}


</style>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.err {
  border: 1px solid red;
}

.success {
  border: #2d815d 1px solid;
}
</style>
