<template>
    <div
            element-loading-spinner="el-icon-loading"
            element-loading-text="玩命加载中……"
            v-loading="loading">
        <el-table
                :data="tableData"
                :row-class-name="tableRowClassName"
                :span-method="objectSpanMethod"
                @expand-change="expandChange"
                @row-click="rowClick"
                @selection-change="handleSelectionChange"
                @sort-change="changeTableSort"
                ref="elTable"

                v-bind="$attrs"
                v-on="$listeners">
            <slot name="first-column"></slot>
            <slot name="second-column"></slot>
            <el-table-column
                    align="center"
                    header-align="center"
                    label="序号" v-if="typeof(pagingData.offset)!='undefined'" width="64">
                <template slot-scope="scope">
                    <span>{{ dataOffset + scope.$index + 1 }}</span>
                </template>
            </el-table-column>
            <el-table-column
                    :align="v.align?v.align:'center'"
                    :header-align="v.header_align?v.header_align:'center'"
                    :key="k"
                    :label="v.title"
                    :min-width="v.width"
                    :prop="typeof(v.value)=='string'?v.value:''"
                    :show-overflow-tooltip="true"
                    :sortable="v.sortable?v.sortable:false"
                    v-for="(v,k) in columns">
                <template slot-scope="scope">
            <span v-if="typeof(v.value)=='string'">
              <span v-if="v.type == 'image'"> <img :alt="scope.row[v.value]" :src="scope.row[v.value]"
                                                   width="40px"></span>
              <span v-else-if="v.value_alias">
                <el-tooltip :content="scope.row[v.value_alias]" effect="dark" placement="top-start"
                            popper-class="ape-table-tooltip" v-if="scope.row[v.value]">
                  <span v-html="scope.row[v.value]"></span>
                </el-tooltip>
                 <span v-else v-html="scope.row[v.value_alias]"></span>
              </span>
              <span v-else v-html="scope.row[v.value]||'/'"></span>
            </span>
                    <span v-else-if="typeof(v.value)=='object'">
              <span :key="k1" v-for="(v1,k1) in v.value">
                <template v-if="v.key">
                  <template v-for="(item, idx) in scope.row[v.key]">
                    <el-tooltip :key="idx" effect="dark" placement="top-start">
                      <div slot="content" v-html="item[v1.value_alias]"></div>
                      <p class="is-value">{{ item[v1.value] }}</p>
                    </el-tooltip>
                  </template>
                </template>
                <span class="more-info-display"
                      v-else-if="scope.row[v1.value] || scope.row[v1.value_alias] || scope.row[v1]">
                  <span class="is-value" v-html="scope.row[v1]" v-if="typeof(v1)=='string'"></span>
                  <span :style="{width:v1.width?parseInt(v1.width)+'px':'72px'}" class="is-label"
                        v-html="v1.label" v-if="typeof(v1)=='object' && v1.label"></span>
                  <template v-if="v1.value_alias && v1.value">
                    <el-tooltip effect="dark" placement="top-start" popper-class="ape-table-tooltip"
                                v-if="scope.row[v1.value]">
                      <div slot="content" v-html="scope.row[v1.value_alias]"></div>
                      <span class="is-value" v-html="scope.row[v1.value]" v-if="typeof(v1)=='object'"></span>
                    </el-tooltip>
                    <span class="is-value" v-else v-html="scope.row[v1.value_alias]"></span>
                  </template>
                  <template v-else>
                    <span class="is-value" v-html="scope.row[v1.value]" v-if="typeof(v1)=='object'"></span>
                  </template>
                </span>
              </span>
            </span>
                </template>
            </el-table-column>
            <slot/>
        </el-table>
        <el-pagination
                :current-page="currentPage"
                :layout="defaultLayout"
                :page-size="pageSize"
                :page-sizes="pageSizes"
                :total="dataTotal"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                background
                v-if="initPaging">
        </el-pagination>
    </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
    name: 'ApeTable',
    props: {
      // ------ 表格相关 ---------
      data: {
        type: Array,
        required: true
      },
      columns: {
        type: Array,
        default: () => []
      },
      loading: {
        default: true
      },
      // ------ 分页相关 ---------
      pagingData: {
        type: Object,
        default: () => {
          return {
            offset: 0
          }
        }
        // required: true
      },
      // 其它参数
      otherParam: {
        type: Object,
        default: function() {
          return {
            expanded_all: false // 默认只展开一个
          }
        }
      },
      scrollToTop: {
        type: Boolean,
        default() {
          return false
        }
      }
    },
    watch: {
      data: {
        deep: true,
        handler() {
          this.rowspan()
        }
      }
    },
    data() {
      return {
        // ------ 表格相关 ---------
        currentRowId: null,
        // ---- 分页相关配置 -------
        apePageSize: 0, // 用于组件切换了每一页数据后的显示
        currentPage: 1,
        // dataTotal: this.pagingData.total,
        // defaultLayout: 'total, sizes, prev, pager, next, jumper',
        mergeFields: ['platform_name'],//合并的字段
        spanObj: {},//合并的数据坐标Map
        positionObj: {}//合并的位置记录


      }
    },
    computed: {
      // 表格数据
      tableData() {
        return this.data
      },
      // ---- 分页相关配置 -------
      // 每页条数，切换
      pageSizes() {
        if (!(typeof (this.pagingData.page_size) == 'undefined') && this.pagingData.page_size < 10) {
          return [this.pagingData.page_size]
        }
        return [10, 15, 20, 50, 100]
      },
      // 每一页大小，默认
      pageSize() {
        if (this.pagingData && !(typeof (this.pagingData.page_size) == 'undefined')) {
          return this.pagingData.page_size
        }
        return 10
      },
      // 初始化分页
      initPaging() {
        if (!(typeof (this.pagingData) == 'undefined')) {
          if (this.pagingData.is_show && this.pagingData.total > this.pageSize) {
            return true
          }
        }
        return false
      },
      // 数组总数
      dataTotal() {
        return this.pagingData.total
      },
      // 数据偏移量，分页序号使用
      dataOffset() {
        return typeof (this.pagingData.offset) == 'undefined' ? 0 : this.pagingData.offset
      },
      // 默认分页结构
      defaultLayout() {
        if (!(typeof (this.pagingData) == 'undefined')) {
          if (this.pagingData.layout) {
            return this.pagingData.layout
          }
        }
        return 'total, sizes, prev, pager, next, jumper'
      }
    },
    methods: {
      rowspan() {
        const fields = this.mergeFields //合并的字段
        this.tableData.forEach((item, index) => {

          fields.forEach((field) => {
            if (index === 0) {
              this.spanObj[field] = []
              this.spanObj[field].push(1)
              this.positionObj[field] = 0
            } else {
              // 合并数据：若和上一行的数据相同，则该字段合并行数加1
              if (this.tableData[index][field] === this.tableData[index - 1][field]) {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              } else {
                this.spanObj[field].push(1)
                this.positionObj[field] = index
              }
            }
          })
        })
      },

      objectSpanMethod({ column, rowIndex }) {

        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }
      ,
      // ------ 表格相关 ---------
      /**
       * @name: luo1o1o1o
       * @desc: 选中项发生变化
       */
      handleSelectionChange(list) {
        this.$emit('selectList', list)
      },
      /**
       * @description 通过数据id，转换对应的行index，并且选中
       * @author YM
       * @date 2019-01-10
       * @returns string
       */
      defaultSelectedRow(rowId) {
        if (rowId) {
          let list = this.tableData
          for (let i = 0, len = list.length; i < len; i++) {
            if (list[i].id == rowId) {
              this.$refs['elTable'].setCurrentRow(list[i])
            }
          }
        } else {
          this.$refs['elTable'].setCurrentRow()
        }
      },
      /**
       * @description 监听行的点击事件,
       * @author YM
       * @date 2019-01-10
       * @returns string
       */
      rowClick(row) {
        this.currentRowId = row.id
        this.$emit('rowClick', row)
      },
      // ------ 分页相关 ---------
      // 获取当前分页相关信息,type类型主要处理删除删除后的情况处理
      // 解决当前页数据全部删除完空白作用
      getPagingInfo(type = 'no_del') {
        if (type == 'del' && (this.pagingData.total - 1) <= (this.currentPage - 1) * this.pageSize) {
          this.currentPage = this.currentPage - 1 > 0 ? this.currentPage - 1 : 1
        }
        let pagingInfo = {
          page_size: this.apePageSize ? this.apePageSize : this.pageSize,
          current_page: this.currentPage
        }
        return pagingInfo
      },
      // pageSize 改变时处理
      handleSizeChange(val) {
        // this.pageSize = val
        this.apePageSize = val
        this.currentPage = 1
        this.$emit('switchPaging')
        //滚动顶部
        if (this.scrollToTop)
          scrollTo(0, 800)
      },
      // currentPage 改变时处理
      handleCurrentChange(val) {
        this.currentPage = val
        this.$emit('switchPaging')
        //滚动顶部
        if (this.scrollToTop)
          scrollTo(0, 800)
      },
      // 处理满足条件行的class类名，作用处理样式
      tableRowClassName({ row }) {
        if (row.row_color == 'gray') {
          return 'gray-row'
        }
        if (row.row_color == 'red') {
          return 'red-row'
        }
        return ''
      },
      // 折叠展开change事件,当前操作行，所有展开的
      expandChange(expandedRows, expanded) {
        if (expanded.length > 1 && !this.otherParam.expanded_all) {
          for (let v of expanded) {
            this.$refs['elTable'].toggleRowExpansion(v, false)
          }
          this.$refs['elTable'].toggleRowExpansion(expandedRows, true)
        }
        this.$refs['elTable'].setCurrentRow(expandedRows)
      },
      changeTableSort(e) {
        this.$emit('changeTableSort', e)
      }
    },
    updated() {
      this.$nextTick(function() {
        if (this.currentRowId) {
          this.defaultSelectedRow(this.currentRowId)
        }
      })
    },
    mounted() {
      this.rowspan()
    }

  }
</script>

<style lang="stylus" scoped>
    table td
        line-height: 26px

    .el-table__body tr.current-row > td
        background-color: #91d5ff !important

    .el-button + .el-button
        margin-left 0
        margin-right 4px

    .iconfont
        font-size 12px

    .el-pagination
        text-align right
        margin-top 24px

    .el-pager li
        font-size 14px

    .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li
        border: solid 1px #f5f5f5
        background-color #ffffff

    .more-info-display
        line-height 28px
        display block

        .is-label
            display inline-block
            text-align right

        .is-value
            display inline-block

    .el-table .gray-row
        background-color #f5f5f5

    .el-table .red-row
        background-color #ffcc00

    .ape-table-tooltip
        max-width 640px
</style>
