<template>
  <div>
    <div v-if="userPermissions.indexOf('team_dept_onJob') !== -1">
      <el-row :gutter='15' v-hasAuthority="'team_dept_onJob'">
        <el-col :span='10'>
          <on-job-employee-list :search-condition='searchCondition' />
        </el-col>
        <el-col :span='14'>
          <resigned-employee-list :search-condition='searchCondition' />
        </el-col>
      </el-row>
    </div>
    <br />
    <div v-if="userPermissions.indexOf('team_dept_effect') !== -1">
      <effect-list :search-condition='searchCondition' v-hasAuthority="'team_dept_effect'"/>
    </div>
    <br />
    <div v-if="userPermissions.indexOf('team_dept_change') !== -1">
      <employee-change-list :search-condition='searchCondition' v-hasAuthority="'team_dept_change'"/>
    </div>
  </div>
</template>

<script>
import OnJobEmployeeList from '@/pages/team/dept/OnJobEmployeeList'
import ResignedEmployeeList from '@/pages/team/dept/ResignedEmployeeList'
import EffectList from '@/pages/team/dept/EffectList'
import EmployeeChangeList from '@/pages/team/dept/EmployeeChangeList'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamDeptReview',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: { EmployeeChangeList, EffectList, ResignedEmployeeList, OnJobEmployeeList },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  methods: {
    // activeMembers(){
    //   this.$notify.success('在职成员一览')
    // }
  }
}
</script>

<style scoped>
.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
