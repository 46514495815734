<template>
  <div>
    <div>
      <span class='title'>组织成员异动一览</span>
      <el-button type='text' :loading='loading' :disabled='loading' @click='getList'
                 icon='el-icon-refresh'></el-button>
    </div>
    <el-row>
      <el-col :span='24'>
        <employee-half-year-change-list :search-condition='searchCondition' :data='dataList' :half-year-tag='`first_half`' />
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :span='24'>
        <employee-half-year-change-list :search-condition='searchCondition' :data='dataList' :half-year-tag='`second_half`' />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EmployeeHalfYearChangeList from '@/pages/team/dept/EmployeeHalfYearChangeList'

export default {
  name: 'EmployeeChangeList',
  components: { EmployeeHalfYearChangeList },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  data() {
    return {
      loading: false,
      dataList: []
    }
  },
  computed: {
    tableData() {
      let rows = [
        { field: 'revenue_total', type_alisa: '总营收' },
        { field: 'num', type_alisa: '团队人数' },
        { field: 'per_revenue', type_alisa: '人效' }
      ]
      rows.forEach(row => {
        let field = row.field
        for (let i = 1; i <= 12; i++) {
          row[`month_${i}`] = null
          let info = this.dataList.find(value => value.month === i)
          if (info) {
            row[`month_${i}`] = info[field]
          }
        }
      })

      return rows
    }
  },
  watch: {
    searchCondition: {
      immediate: true,
      deep: true,
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      if (this.searchCondition.group_id && this.searchCondition.year) {
        this.loading = true
        let { list } = await this.$api.getTeamChangeAll({
          dept_id: this.searchCondition.group_id,
          year: this.searchCondition.year
        })

        this.loading = false
        this.dataList = [...list]
      }

    }
  }
}
</script>

<style scoped>
.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
