<template>
  <div>
    <div ref="contrast" style="width: 100%;height:600px;margin-top: 20px;"></div>
  </div>

</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'LineChart',
  props: {
    searchCondition: {
      type: Object,
      default: () => ({ year: null, group_id: null, group_name: '', quarter: 0, release_status: 'Y' })
    }
  },
  data() {
    return {
      chartOption: {},
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // if (val.length != 0) {
        this.getChartDataRevenue()
        // } else {
        //   this.echartShow = false
        // }
      }
    }
  },
  update() {
    this.getChartDataRevenue()
  }, mounted() {
    this.getChartDataRevenue()
  },
  beforeDestroy() {
    let myChart = echarts.init(this.$refs.contrast)
    myChart.clear()
  },
  methods: {
    async getChartDataRevenue() {
      let myChart = echarts.init(this.$refs.contrast)

      let chartOption = {
        title: {
          text: `${this.searchCondition.year}年涨粉情况 vs 营收情况`,
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false,
            snap: true

            // label:{
            //   formatter: 'some text {value} some text {Object}'
            // }
          }
        },
        legend: {
          left: '60%'
        },
        toolbox: {
          feature: {
            restore: {},
            saveAsImage: {}
          }
        },
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ]
        },
        grid: [
          {
            left: 60,
            right: 50,
            height: '35%'
          },
          {
            left: 60,
            right: 50,
            top: '55%',
            height: '35%'
          }
        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: { onZero: true },
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
          },
          {
            gridIndex: 1,
            type: 'category',
            boundaryGap: false,
            axisLine: { onZero: true },
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            position: 'top'
          }
        ],
        yAxis: [
          {
            name: '涨粉情况',
            type: 'value'
          },
          {
            gridIndex: 1,
            name: '营收情况',
            type: 'value',
            inverse: true
          }
        ],
        series: []
      }
      if (this.searchCondition && this.searchCondition.year && this.searchCondition.group_id) {
        let data = []
        data = await this.$api.getPerformanceRrendGroupList(this.searchCondition)
        let Revenuedata = await this.$api.getRevenueList(this.searchCondition)

        this.$nextTick(() => {
          let newArr = []
          if (data.length > 0) {
            data.forEach((item) => {
              let chartData = {
                data: [],
                name: '涨粉情况',
                type: 'line',
                symbolSize: 8
              }
              chartData.name = item.platform_name
              chartOption.legend.data = []
              chartOption.legend.data.push(item.platform_name)
              for (let i = 1; i <= 12; i++) {
                if (item[`month_${i}`]) {
                  chartData.data.push(item[`month_${i}`])
                } else {
                  chartData.data.push(0)
                }
              }
              newArr.push(chartData)
            })
            chartOption.series = newArr
            Revenuedata.forEach((item) => {
              let chartData = {
                data: [],
                name: '营收情况',
                type: 'line',
                  xAxisIndex: 1,
                  yAxisIndex: 1,
                  symbolSize: 8
                }
                chartData.name = item.platform_name
                chartOption.legend.data.push(item.platform_name)
                for (let i = 1; i <= 12; i++) {
                  if (item[`month_${i}`]) {
                    chartData.data.push(item[`month_${i}`])
                  } else {
                    chartData.data.push(0)
                  }
                }
                newArr.push(chartData)
              })
              //
              myChart.setOption(chartOption)

              this.loadingStatus = false
            }
          })
        }
        // if (this.searchCondition && this.searchCondition.year) {
        //   let data = await this.$api.getPerformanceRrendGroupList(this.searchCondition)
        //   this.$nextTick(() => {
        //     let newArr = []
        //     data.forEach((item) => {
        //       let chartData = {
        //         data: [], type: 'line',
        //         markPoint: {
        //           data: [
        //             { type: 'max', name: 'Max' },
        //             { type: 'min', name: 'Min' }
        //           ]
        //         },
        //         markLine: {
        //           data: [{ type: 'average', name: 'Avg' }],
        //           label: {
        //             formatter: '整年平均值'
        //           }
        //         }
        //       }
        //       // chartData.name = item.platform_name
        //       // chartOption.legend.data.push(item.platform_name)
        //       for (let i = 1; i <= 12; i++) {
        //         if (item[`month_${i}`]) {
        //           chartData.data.push(item[`month_${i}`])
        //         } else {
        //           chartData.data.push(0)
        //         }
        //       }
        //       newArr.push(chartData)
        //     })
        //     chartOption.series = newArr
        //
        //   })
        // }
        // if (this.dataList && this.dataList.length > 0) {
        //
        // }
      }
    },
  }
</script>

<style scoped>


</style>
