<template>
  <el-dialog v-on='$listeners' v-bind='$attrs' width='400px' title='请填写离职时间' @open='onOpen' @close='onClose'
             :modal-append-to-body='true' :append-to-body='true'>
    <el-form size='small' inline>
      <el-form-item>
        <el-date-picker
          style='text-align: center;margin: 0 auto'
          v-model='departTime'
          type='date'
          format='yyyy年MM月dd日'
          value-format='yyyy-MM-dd'
          placeholder='选择日期'>
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type='danger' @click='handleLeave'>确认离职</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'SetEmployeeLeave',
  props: {
    teamMemberId: {
      type: [Number, String],
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      departTime: ''
    }
  },
  methods: {
    onOpen() {
      this.departTime = ''
    },
    onClose() {
    },
    close() {
      this.$emit('update:visible', false)
    },
    async handleLeave() {
      let params = {
        id: this.teamMemberId,
        leave_date: this.departTime
      }
      console.log(params)

      this.$confirm(
        `确定离职当前成员,离职时间为【${params.leave_date}】？`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
        let data = await this.$api.teamMemberBatchLeave({ items: [params] })
        if (data) {
          if (data.errs.length != 0) {
            this.$message.warning(`离职失败,原因：${data.errs[0].msg}`)
          } else {
            this.$message.success(`离职成功`)
            this.close()

          }
        }
      })

    }
  }
}
</script>

<style scoped>

</style>