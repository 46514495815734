<template>
  <div>
    <div>
      <span class='title'>红人签约/解约情况一览 </span>
      <el-button type='text' icon='el-icon-refresh' @click='getList' :loading='loading'></el-button>
    </div>
    <div class='default-table'>
      <el-table :data='tableData' @cell-click='showDetail' border>
        <!--        <el-table-column type='index' label='序号' width='60' align='center'></el-table-column>-->
        <el-table-column prop='type_alias' label='月份' align='center' width='100'>
          <template slot-scope='{row}'>{{ row.type_alias }}</template>
        </el-table-column>
        <el-table-column :prop='`${column.value}`' :label='`${column.title}`' v-for='(column,index) in columns'
                         :key='index' header-align='center' align='right' width='110'>
          <template slot-scope='{row,column}'>
            <el-link type='primary' title='查看详情'>{{ row[column.property] }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop='total' label='汇总' align='center' width='120'></el-table-column>
      </el-table>
    </div>
    <el-dialog :title='dialogTitle' width='1000px' :visible.sync='dialogTableVisible'>
      <div>
        <el-table :data='gridData' max-height='600' border>
          <el-table-column type='index' label='序号' width='60' align='center'></el-table-column>
          <el-table-column prop='nickname' label='红人昵称' width='170' align='center' sortable>
            <template slot-scope='{row}'>
              <artist-nickname :info='{artist_id:row.artist_id,nickname:row.nickname}' />
            </template>
          </el-table-column>
          <el-table-column prop='sign_at' label='签约时间' width='140' align='center' sortable>
            <template slot-scope='{row,column}'>
              {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
            </template>
          </el-table-column>
          <el-table-column prop='in_at' label='调入时间' width='140' align='center' sortable>
            <template slot-scope='{row,column}'>
              {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
            </template>
          </el-table-column>
          <el-table-column prop='fired_at' label='解约时间' width='140' align='center' sortable>
            <template slot-scope='{row,column}'>
              {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
            </template>
          </el-table-column>
          <el-table-column prop='out_at' label='调出时间' width='140' align='center' sortable>
            <template slot-scope='{row,column}'>
              {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
            </template>
          </el-table-column>
          <el-table-column label='运营时长' width='160' align='center'>
            <template slot='header'>
              <span>运营时长（天）</span>
              <el-tooltip class='item' effect='dark' content='运营时长=解约时间/调出时间-签约时间-调入时间' placement='right'>
                <i class='el-icon-question'></i>
              </el-tooltip>
            </template>
            <template slot-scope='{row}'>{{ row.duration || '/' }} 天</template>
          </el-table-column>
          <!--          <el-table-column label='备注' prop='remark' show-overflow-tooltip width='400'>-->

          <!--          </el-table-column>-->
        </el-table>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'

export default {
  name: 'SignMonthlyList',
  components: { ApeTable },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  watch: {
    searchCondition: {
      immediate: true,
      deep: true,
      handler() {
        this.getList()
      }
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      columns: [],
      typeMap: {},
      dialogTitle: '',
      dialogTableVisible: false,
      gridData: []
    }
  },
  created() {
    this.columns = [
      //   {
      //   value: 'type',
      //   title: '月份',
      //   width: 120,
      //   align: 'center'
      // }
    ]
    for (let i = 1; i <= 12; i++) {
      this.columns.push({
        value: `month_${i}`,
        title: `${i}月`,
        width: 120,
        header_align: 'center',
        align: 'right'
      })
    }
    // this.columns.push({
    //   value: 'total',
    //   title: '汇总',
    //   width: 120,
    //   header_align: 'center',
    //   align: 'right'
    // })
  },
  computed: {
    tableData() {
      let rows = []
      Object.keys(this.typeMap).forEach((type) => {
        let type_alias = this.typeMap[type]
        rows.push({ type_alias: type_alias, type: type })
      })
      rows.forEach(row => {
        for (let i = 1; i <= 12; i++) {
          row[`month_${i}`] = null
          // type数据类型："1" == 1
          let info = this.dataList.find(value => value.month === i && value.type + '' === row.type)
          if (info) {
            row[`month_${i}`] = info['quantity']
          }
        }
      })

      return rows
    }
  },
  methods: {
    init() {
      this.dataList = []
      this.getList()
    },
    async getList() {
      this.dataList = []
      // this.$notify.info(`${this.searchCondition.year}年，组ID：${this.searchCondition.group_id}`)
      if (this.searchCondition.year && this.searchCondition.group_id) {
        this.loading = true
        let { list, typeMap } = await this.$api.getTeamKoLSituationOverview({
          year: this.searchCondition.year,
          group_id: this.searchCondition.group_id
        })
        this.dataList = list || []
        this.typeMap = typeMap
        setTimeout(() => {
          this.loading = false
        }, 500)
      } else {
        this.$notify.warning('请选择需要展示的组和年份！！！')
      }

    },
    // eslint-disable-next-line no-unused-vars
    async showDetail(row, column, cell, event) {
      event.preventDefault()
      if (column.property.indexOf('month_') > -1) {
        let type = row.type
        let monthArr = column.property.split('_')
        let month = monthArr.length === 2 ? monthArr[1] : null
        let year = this.searchCondition.year
        let dept_id = this.searchCondition.group_id
        if (year && month && dept_id) {
          this.dialogTitle = `${year}年${month}月【${this.searchCondition.group_name}】红人签约/解约情况一览:${this.typeMap[type]}`
          // this.$notify.warning(this.dialogTitle)
          let { list } = await this.$api.getTeamKoLSituationViewDetail({
            year: year,
            month: month,
            group_id: dept_id,
            type: type
          })
          this.gridData = [...list]
          this.dialogTableVisible = true

        } else {
          this.$notify.warning(`参数异常：【${dept_id}】${year}年${month}月`)
        }
      }
    }
  }
}
</script>

<style scoped>
.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
