<template>
  <div>
    <div class='default-table' style='display: inline-block'>
      <el-table :data='tableData' @cell-click='showDetail' :span-method='objectSpanMethod' border>
        <el-table-column prop='type_alisa' align='center' width='120'>
          <template slot='header'>
            <span>月份</span>
            <el-tooltip class='item' effect='dark'
                        :content='`半年度离职率=半年度离职总人数/（${beginMonth}月份/${lastMonth}月份在职人数+半年度入职人数汇总）`' placement='right'>
              <i class='el-icon-question'></i>
            </el-tooltip>
          </template>
          <template slot-scope='scope'>
            <span class='th'>{{ scope.row.type_alisa }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for='(v,k) in columns'
          :key='k'
          :prop="typeof(v.value)=='string'?v.value:''"
          :label='v.title'
          :width='v.width'
          :show-overflow-tooltip='true'
          :header-align="v.header_align?v.header_align:'center'"
          :align="v.align?v.align:'center'"
          :sortable='v.sortable?v.sortable:false'>
          <template slot-scope='{row,column}'>
            <el-link type='primary' title='点击查看详情'>{{ row[column.property] }}</el-link>
          </template>
        </el-table-column>
        <el-table-column :label='`合计`' prop='total' width='100' header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <el-link @click='showDetail(row)'>{{ row[column.property] }}</el-link>
          </template>
        </el-table-column>
        <el-table-column :label='`${halfYearTitle}`' prop='halfYearRate' width='100' header-align='center'
                         align='center'>
          <template>
            <span class='rate'> {{ halfYearRate }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style='display: inline-block;text-align: left;vertical-align: top'>
      &nbsp;<i class='el-icon-info'></i>{{ `半年度离职率=半年度离职总人数/（${beginMonth}月份在职人数+半年度入职人数汇总）` }}
    </div>

    <!--    团队详情-->
    <el-dialog :title='dialogTitle' :visible.sync='dialogTableVisible' width='1200px'>
      <div style='margin-bottom: 5px'>
        <el-card>
          本月在职成员：
          <el-tag effect='plain' v-for='(member,index) in onJobMembers' :key='index'>{{ index + 1 }}）{{ member.name }}&nbsp;</el-tag>
        </el-card>
      </div>
      <el-table :data='gridData' max-height='400'>
        <el-table-column type='index' label='序号' width='60' align='center'></el-table-column>
        <!--        <el-table-column label='编号' prop='id' width='80' align='center'></el-table-column>-->
        <el-table-column label='岗位' prop='position' align='center' sortable></el-table-column>
        <el-table-column label='姓名' prop='name' align='center' sortable>
        </el-table-column>
        <el-table-column label='调入时间' prop='in_at' align='center' sortable>
        </el-table-column>
        <el-table-column label='入职时间' prop='hired_date' align='center' sortable>
          <template slot-scope='{row}'>
            {{ row.hired_date === '1970-01-01' ? '' : row.hired_date }}
          </template>
        </el-table-column>
        <el-table-column label='离职时间' prop='leave_date' align='center' sortable></el-table-column>
        <el-table-column label='调动时间' prop='out_at' align='center' sortable></el-table-column>
        <el-table-column label='调动部门' prop='out_dept_name' align='center' sortable></el-table-column>
        <el-table-column label='创建时间' prop='created_at' align='center' width='120' show-overflow-tooltip
                         sortable></el-table-column>
        <el-table-column label='修改时间' prop='updated_at' align='center' width='120' show-overflow-tooltip
                         sortable></el-table-column>
      </el-table>
      <div style='margin-top: 5px'>
        <el-card>
          <div slot='header' class='clearfix'>
            <span class='title' style='font-weight: 600'>异动分析</span>
          </div>
          <div>
            <ul>
              <li><span> 入职：{{ changeMap.entry.num }} 人 {{ changeMap.entry.names.join('、') }}</span></li>
              <li><span> 离职：{{ changeMap.leave.num }} 人 {{ changeMap.leave.names.join('、') }}</span></li>
              <li><span> 调入：{{ changeMap.in.num }} 人 {{ changeMap.in.names.join('、') }}</span></li>
              <li><span> 调出：{{ changeMap.out.num }} 人 {{ changeMap.out.names.join('、') }}</span></li>
              <li><span> 在职：{{ changeMap.on.num }} 人 {{ changeMap.on.names.join('、') }}</span></li>
            </ul>
          </div>
          <!--          <el-tag effect='plain' v-for='(member,index) in onJobMembers' :key='index'>{{ index + 1 }}）{{ member.name }}&nbsp;</el-tag>-->
        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'EmployeeHalfYearChangeList',
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    },
    halfYearTag: {
      type: String,
      default() {
        return ''
      }
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      dialogTitle: '',
      currMonth: null,
      gridData: [],
      columns: [],
      // dataList: [
      //   { type: '入职' },
      //   { type: '离职' },
      //   { type: '在职' }
      // ],
      //离职率
      turnover_rate_alias: '离职率',
      turnover_rate_col: '',
      beginMonth: 1,
      lastMonth: 6,
      halfYearTitle: '上半年'
      // halfYearRate: 0
    }
  },
  watch: {
    halfYearTag() {
      this.calcCols()
    }
  },
  computed: {
    tableData() {
      let rows = [
        { field: 'entry_num', type_alisa: '入职', total: 0 },
        { field: 'leave_num', type_alisa: '离职', total: 0 },
        { field: 'on_num', type_alisa: '在职', total: 0 }
      ]
      rows.forEach(row => {
        let field = row.field
        let total_field = `${row.field}_total`
        let total = 0
        for (let i = this.beginMonth; i <= this.lastMonth; i++) {
          row[`month_${i}`] = null
          let info = this.data.find(value => value.month === i)
          if (info) {
            row[`month_${i}`] = info[field] / 1
            total += row[`month_${i}`] / 1
          }
        }
        if (['entry_num', 'leave_num'].indexOf(row.field) > -1) {
          row[total_field] = total
          row['total'] = total
        } else {
          row[total_field] = '/'
          row['total'] = '/'
        }
      })

      return rows
    },
    halfYearRate() {
      if (this.tableData && this.tableData.length === 3) {
        let leave_num_total = this.tableData[1]['total'] / 1
        let entry_num_total = this.tableData[0][`total`] / 1
        //在职人数：半年第1个月的
        let begin_total = this.tableData[2][`month_${this.beginMonth}`] / 1
        if (leave_num_total === 0) {
          return 0
        }
        return (begin_total + entry_num_total) > 0 ? Math.round(10000 * leave_num_total / (begin_total + entry_num_total)) / 100 : '/'
      }

      return 0
    },
    onJobMembers() {
      let members = []
      this.gridData.forEach((item) => {
        let index = members.findIndex(value => value.userid === item.userid)
        if (index === -1)
          members.push(item)
      })
      return members
    },
    changeMap() {
      let currYear = this.searchCondition.year / 1
      let currMonth = this.currMonth / 1
      let map = {
        entry: { num: 0, names: [] },
        leave: { num: 0, names: [] },
        in: { num: 0, names: [] },
        out: { num: 0, names: [] },
        on: { num: 0, names: [] }
      }
      this.gridData.forEach((item) => {
        map.on.num += 1
        map.on.names.push(item.name)
        //该月入职的人数
        if (item.hired_date && item.hired_date.indexOf('-') > -1) {
          let arr = item.hired_date.split('-')
          if (arr[0] / 1 === currYear && arr[1] / 1 === currMonth) {
            map.entry.num += 1
            map.entry.names.push(item.name)
          }
        }
        //该月离职人数
        if (item.leave_date && item.leave_date.indexOf('-') > -1) {
          let arr = item.leave_date.split('-')
          if (arr[0] / 1 === currYear && arr[1] / 1 === currMonth) {
            map.leave.num += 1
            map.leave.names.push(item.name)
          }
        }
        //该月调入人数
        if (item.in_at && item.in_at.indexOf('-') > -1) {
          let arr = item.in_at.split('-')
          if (arr[0] / 1 === currYear && arr[1] / 1 === currMonth) {
            map.in.num += 1
            map.in.names.push(item.name)
          }
        }
        //该月调出人数
        if (item.out_at && item.out_at.indexOf('-') > -1) {
          let arr = item.out_at.split('-')
          if (arr[0] / 1 === currYear && arr[1] / 1 === currMonth) {
            map.out.num += 1
            map.out.names.push(item.name)
          }
        }
      })
      // console.log(map)
      return map
    }
  },
  methods: {
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'halfYearRate') {
        if (rowIndex === 0) {
          return {
            rowspan: 3,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }

      }

      //末尾查询一行合计：团队奖金
      // if (rowIndex === this.tblData.length - 1) {
      //   // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
      //   if ((columnIndex + 1) % 3 === 0) {
      //     return [1, 3]
      //   } else {
      //     return [0, 0]
      //   }
      // } else if (rowIndex === this.tblData.length - 2) {
      //   // 本组总涨粉：
      //   if (columnIndex === 0) {
      //     return [0, 0]
      //   } else if (columnIndex === 1) {
      //     return [1, 2]
      //   } else {
      //     return [1, 1]
      //   }
      // } else {
      //   const fields = this.mergeFields
      //   if (column.property && fields.indexOf(column.property) > -1) {
      //     const _row = this.spanObj[column.property][rowIndex]
      //     const _col = _row > 0 ? 1 : 0
      //     return {
      //       rowspan: _row,
      //       colspan: _col
      //     }
      //   }
      // }

    },
    calcCols() {
      let beginMonth = 1
      let lastMonth = 6
      let halfYearTitle = ''
      if (this.halfYearTag === 'first_half') {
        beginMonth = 1
        lastMonth = 6
        halfYearTitle = '上半年'

      } else if (this.halfYearTag === 'second_half') {
        beginMonth = 7
        lastMonth = 12
        halfYearTitle = '下半年'
      }
      this.columns = []
      for (let i = beginMonth; i <= lastMonth; i++) {
        this.columns.push({
          value: `month_${i}`,
          title: `${i}月`,
          width: 120,
          header_align: 'center',
          align: 'right'
        })
      }
      this.halfYearTitle = `${halfYearTitle}离职率`
      //
      // this.columns.push({
      //   value: ``,
      //   title: `${halfYearTitle}离职率`,
      //   width: 120,
      //   header_align: 'center',
      //   align: 'right'
      // })
      this.beginMonth = beginMonth
      this.lastMonth = lastMonth
    },
    // eslint-disable-next-line no-unused-vars
    async showDetail(row, column, cell, event) {
      //
      // console.log(row)
      event.preventDefault()
      if (column.property.indexOf('month_') > -1) {
        let monthArr = column.property.split('_')
        let month = monthArr.length === 2 ? monthArr[1] : null
        this.currMonth = month
        let year = this.searchCondition.year
        let dept_id = this.searchCondition.group_id
        if (year && month && dept_id) {
          this.dialogTitle = `${year}年${month}月在职人员详情`
          let { list } = await this.$api.getTeamChangeViewDetail({
            year: year,
            month: month,
            dept_id: dept_id
          })
          this.gridData = [...list]
          this.dialogTableVisible = true

        } else {
          this.$notify.warning(`参数异常：【${dept_id}】${year}年${month}月`)
        }
      }
    }
  },
  created() {
    this.calcCols()
  }
}
</script>

<style scoped>

</style>
