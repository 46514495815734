<template>
  <div>
    <el-link @click='initList' style='padding: 10px 0' type='primary'>
      营收绩效目标<span style='color: #1a1a1a'>与完成情况</span>
    </el-link>
    <div class='panel'>
      <div class='default-table'
           v-loading='loadingStatus'>
        <el-table
          :cell-class-name='cellClassName'
          :data='tblData'
          :span-method='objectSpanMethod'
          border
          style='width: 100%'>
          <el-table-column
            align='center'
            label='红人昵称'
            min-width='100'
            prop='nickname'>
            <template slot-scope='{row}'>
              <artist-nickname :info='row' />
            </template>
          </el-table-column>
          <el-table-column
            align='center'
            label='平台'
            min-width='100'
            prop='platform_name'>
            <template slot-scope='{row}'>
              <span>{{ row.platform_name }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align='center'
            label='收入分类'
            min-width='100'
            prop='income_type'>
          </el-table-column>
          <el-table-column
            align='center'
            header-align='center'
            label='上季月营收均值' min-width='110' prop='average_value'>
            <template slot-scope='scope'>
              <span v-if='scope.$index<tblData.length-1'>{{ valFormat(scope.row[`average_value`]) }}</span>
              <span v-else>{{ (scope.row[`average_value`]) }}</span>
            </template>
          </el-table-column>
          <el-table-column :key='index' :label='`${month}月`' align='center'
                           v-for='(month,index) in quarterlyMonths'>
            <el-table-column
              :prop='`quotient_${month}`'
              align='center'
              header-align='center' label='系数' min-width='100'>
              <template slot-scope='{row,$index}'>
                    <span :class='calcBonusClass(row[`amount_${ month }`])' v-if='$index===tblData.length-1'>{{
                        moneyFormat(row[`amount_${month}`])
                      }}</span>
                <span v-else>{{ row[`quotient_${month}`] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`flag_value_${month}`'
              align='center'
              header-align='center' label='目标' min-width='100'>
              <template slot-scope='{row,$index}'>
                    <span :class='calcBonusClass(row[`amount_${ month }`])' v-if='$index===tblData.length-1'>{{
                        moneyFormat(row[`amount_${month}`])
                      }}</span>
                <span v-else>{{ valFormat(row[`flag_value_${month}`]) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`real_value_${month}`'
              align='center'
              header-align='center' label='完成' min-width='100'>
              <template slot-scope='{row,$index}'>
                    <span :class='calcBonusClass(row[`amount_${ month }`])' v-if='$index===tblData.length-1'>{{
                        moneyFormat(row[`amount_${month}`])
                      }}</span>
                <span v-else>{{ valFormat(row[`real_value_${month}`]) }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PerformanceRevenue',
  computed: {
    quarterlyMonths() {
      let quarter = this.searchCondition.quarter
      return [1 + (quarter - 1) * 3, 2 + (quarter - 1) * 3, 3 + (quarter - 1) * 3]
    },
    tblData() {
      if (this.dataList.length > 0) {
        let calcRow = this.calcCol()

        //合计数据-初始化
        let appendRows = [{ nickname: '本组总营收', platform_name: '本组总营收', income_type: '本组总营收', ...calcRow }]
        appendRows.push({ ...this.bonusRow })

        return [...this.dataList, ...appendRows]
      }

      return []
    }
  },
  props: {
    searchCondition: {
      type: Object,
      default: () => ({ year: null, group_id: null, group_name: '', quarter: 0, release_status: 'Y' })
    }
  },
  data() {
    return {
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      //end:鼠标右键菜单
      commitLoading: false,
      showEditIcon: true,
      currentRow: {},
      currentColumn: {},
      platforms: [],//红人所有平台
      showPlatforms: [],//要显示的平台
      loadingStatus: false,
      showIndex: false,
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      fixFields: ['group_name', 'platform_name', 'income_type', 'average_value'],
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      dataList: [],
      bonusRow: {},
      bonus_items: [],//团队奖金
      // tblData: [],//表格数据
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      checkMonthList: []//核算完成的月份
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        this.initList()
      }
    }
  },
  created() {
    this.initList()
  },
  methods: {
    // 处理搜索条件
    async initList() {

      this.getCalcFields()
      //当前已核算完成的月份
      // await this.getCheckMonths()

      this.loadingStatus = true
      this.dataList = []
      if (this.searchCondition && this.searchCondition.year && this.searchCondition.group_id) {
        let { list, bonus_items } = await this.$api.getHistoryListOfPerformanceRevenue(this.searchCondition)
        this.bonus_items = bonus_items || 0
        this.bonusRow = {
          nickname: '团队奖金',
          platform_name: '团队奖金',
          income_type: '团队奖金',
          average_value: '团队奖金'
        }
        if (this.bonus_items.length > 0) {
          let row = {}
          this.bonus_items.forEach((item) => {
            row[`amount_${item.month}`] = item.amount
          })
          this.bonusRow = {
            nickname: '团队奖金',
            platform_name: '团队奖金',
            income_type: '团队奖金',
            average_value: '团队奖金', ...row
          }
        }

        this.$nextTick(() => {
          this.dataList = list
          this.loadingStatus = false
          this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
        })

      }
    },
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化

          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              if (field === 'platform_name') {
                //平台列，特殊处理
                if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                } else {
                  this.spanObj[field].push(1)
                  this.positionObj[field] = index
                }
              } else {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              }

            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //末尾查询一行合计：
      if (rowIndex === this.tblData.length - 1) {
        // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
        if (columnIndex < 4) {
          if ((columnIndex + 1) % 4 === 0) {
            return [1, 4]
          } else {
            return [0, 0]
          }
        } else if ((columnIndex + 1) % 3 === 0) {
          return [1, 3]
        } else {
          return [0, 0]
        }
      } else if (rowIndex === this.tblData.length - 2) {
        // 本组总涨粉：
        if (columnIndex < 2) {
          return [0, 0]
        } else if (columnIndex === 2) {
          return [1, 3]
        } else {
          return [1, 1]
        }
      } else {
        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }
    },

    valFormat(value) {
      return this.$utils.numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },

    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 2) {
          return
        }
        if (index === 2) {
          sums[index] = '合计:'
          return
        }
        // console.log(`${index}）${column.property}`)
        if (column.property.indexOf('average_value') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // // 格式化数据
          switch (column.property) {
            default:
              sums[index] = this.valFormat(sums[index])
              break
          }
        }
      })

      return sums
    },

    // async getCheckMonths() {
    //   let { months } = await this.$api.getPerformanceCheckedMonths({
    //     type: 'revenue',
    //     group_id: this.searchCondition.group_id,
    //     year: this.searchCondition.year,
    //     months: this.quarterlyMonths
    //   })
    //   //当前已核算完成的月份
    //   this.checkMonthList = [...months]
    // },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    rowClassName({ row, rowIndex }) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''

    }
  }
}
</script>

<style scoped>
.panel {
  margin-bottom: 50px;
}
</style>

