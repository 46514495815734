<template>
  <div>
    <el-form>
      <el-form-item>
        <el-cascader
            v-model='depart.value'
            :options='depart.options'
            @change="handleChange($event)"
            :props="{ expandTrigger: 'hover' ,value: 'userid', label: 'label', children: ''}"
            style='width: 100%' clearable></el-cascader>
      </el-form-item>
    </el-form>

  </div>

</template>

<script>
export default {
  name: 'departUser',
  props: {
    dialogTitle: {
      type: String,
      default() {
        return '奖金分配小组成员选择'
      }
    }
  },
  data() {
    return {
      depart: {
        mode: [],
        options: []
      },
      valueListSelected: [],
      batchDepartIds: []
    }
  },
  mounted() {
    this.getDepartList()
  },
  methods: {
    async getDepartList() {
      let data = await this.$api.leaveTeamMemberUserList()
      let mapData = []
      data.forEach((item) => {
        let retun = mapData.find(i => {
          return i.main_dept_id === item.main_dept_id
        })
        if (!retun) {
          item.user = { ...item.user, dept_id: item.main_dept_id, dpt_name: item.main_dept_name }
          item.child = [item.user]
          item.label = item.main_dept_name
          item.user.name = item.user.nick
          mapData.push(item)
        } else {
          item.label = item.main_dept_name
          item.user.name = item.user.nick
          item.user.dept_id = item.main_dept_id
          item.user.dpt_name = item.main_dept_name

          retun.child.push(item.user)
        }
      })
      this.depart.options = mapData
    },
    handleChange(selectArr) {
      let dataRow = {}
      this.depart.options.find(item => { //这里的options就是数据源
        if (item.userid == selectArr) {
          dataRow = item
        }
      })
      this.$emit('members', dataRow)
      return selectArr
    },
    onOpen() {
    },
    handleDepart() {
    },
    async batchBtn() {

    }

  }

}
</script>

<style scoped>

</style>
