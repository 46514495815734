<template>
  <div>
    <router-view v-show="$route.matched.length===3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length===2">
      <div class="main-page-content">
        <TrendChart ref="tChart"></TrendChart>
      </div>
    </PageHeaderLayout>

  </div>
</template>

<script>

import TrendChart from "@/pages/dashboard/trend/TrendChart";
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import {mapGetters} from "vuex";

export default {
  name: "TrendDataIndex",
  components: {PageHeaderLayout, TrendChart},
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  mounted() {
    this.$refs['tChart'].init()
  }
}
</script>

<style scoped>

</style>
