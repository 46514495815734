<template>
  <div style='margin-bottom: 10px'>
    <el-row>
      <el-col :span='1.5'>
        <span class='title'>组织离职/调动一览</span>
        <el-button type='text' :loading='loading' :disabled='loading' @click='getList'
                   icon='el-icon-refresh'></el-button>
        <!--        <svg-icon icon-class="depart" style="cursor: pointer;display: inline-block" @click="departUserBtn"></svg-icon>-->

      </el-col>
    </el-row>
    <div class='default-table'>
      <el-table :data='dataList' max-height='600' border>
        <el-table-column type='index' label='序号' width='80' align='center'></el-table-column>
        <el-table-column label='岗位' prop='position' align='center' sortable></el-table-column>
        <el-table-column label='姓名' prop='name' align='center' sortable>
          <template slot-scope='{row}'>
            <el-link type='primary' @click='syncEvent(row.id)' :title='`userid:${row.userid}`'> {{ row.name }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label='入职时间' prop='hired_date' align='center' sortable>
          <template slot-scope='{row}'>
            {{ row.hired_date === '1970-01-01' ? '' : row.hired_date }}
          </template>
        </el-table-column>
        <el-table-column label='调入时间' prop='in_at' align='center' sortable></el-table-column>
        <el-table-column label='离职时间' prop='leave_date' align='center' sortable></el-table-column>
        <el-table-column label='调动时间' prop='out_at' align='center' sortable></el-table-column>
        <el-table-column label='调动部门' prop='out_dept_name' align='center' sortable></el-table-column>
      </el-table>
    </div>
    <div>
      <pagination :hidden='total<pageData.page_size' :total.sync='total' :limit.sync='pageData.page_size'
                  :page.sync='pageData.current_page'
                  @pagination='getList' />
    </div>

    <!--    成员信息-->
    <MemberDetail :visible.sync='dialogVisible' :member-id='currId' @saved='getList' />

    <!--    <addUser :dialogTitle="dialogTitle"-->
    <!--        :dialogType="'depart'"     :visible.sync="showAddMember"-->
    <!--             @closeDiag="closeDiag"   v-if="showAddMember"></addUser>-->
  </div>
</template>

<script>
import addUser from '@/pages/team/components/addUser'
import EmployeeChangeList from '@/pages/team/dept/EmployeeChangeList'
import EmployeeInfo from '@/pages/team/dept/EmployeeInfo'
import LeaveEmployeeInfo from '@/pages/team/dept/LeaveEmployeeInfo'
import MemberDetail from '@/pages/team/dept/MemberDetail'

export default {
  name: 'ResignedEmployeeList',
  components: { MemberDetail, LeaveEmployeeInfo, EmployeeInfo, EmployeeChangeList, addUser },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  data() {
    return {
      dataList: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      loading: false,
      showAddMember: false,
      dialogTitle: '添加成员',
      dialogVisible: false,
      currId: null
    }
  },
  watch: {
    searchCondition: {
      immediate: true,
      deep: true,
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    closeDiag() {
      this.showAddMember = false
    },
    //
    departUserBtn() {
      this.dialogTitle = '离职列表'
      this.showAddMember = true
    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.group_id) {
        cond['dept_id'] = this.searchCondition.group_id
      }
      if (this.searchCondition.year) {
        cond['year'] = this.searchCondition.year
      }
      return cond
    },
    async getList() {
      if (this.searchCondition.group_id && this.searchCondition.year) {
        let searchCondition = this.handleSearchCondition()
        searchCondition = Object.assign(searchCondition, this.pageData)
        this.loading = true
        let { list, pages } = await this.$api.getTeamMemberRemovalMembers(searchCondition)
        // console.log(list)
        this.dataList = [...list]
        this.pageData.page_size = pages.page_size || 15
        this.pageData.current_page = pages.current_page || 1
        this.total = pages.total || 0
        this.loading = false
      }
    },
    async syncEvent(id) {
      if (id) {
        this.currId = id
        this.dialogVisible = true
      } else
        this.$notify.warning('成员的钉钉ID未获取')
    }
  }
}
</script>

<style scoped>
.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
