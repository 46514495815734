<template>
  <div id="main" ref="bar_dv" style="width: 100%;height:400px;margin-top: 20px;"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'LineChart',
  props: {
    dataList: {
      type: Array
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      chartOption: {}
    }
  },
  watch: {
    dataList: {
      deep: true,
      handler() {
        this.getChartDataRevenue()
      }
    }
  },
  methods: {
    async getChartDataRevenue() {
      // let chartDom = document.getElementById('main')
      let myChart = echarts.init(this.$refs.bar_dv)
      let chartOption = {
        title: {
          text: `${this.title}情况一览`,
          top: 0,
          left: '40%',
          textStyle: {
            color: '#303133',
            fontWeight: 'bold'
          }
        },
        toolbox: {
          orient: 'vertical',
          itemSize: '24',
          feature: {
            magicType: { show: true, type: ['bar', 'line'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          data: [],
          left: '60%'
        },
        series: []
      }
      if (this.dataList && this.dataList.length > 0) {
        let newArr = []
        this.dataList.forEach((item) => {
          let chartData = {
            data: [], type: 'line',
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' }
              ]
            },
            markLine: {
              data: [{ type: 'average', name: 'Avg' }],
              label: {
                formatter: '整年平均值'
              }
            }
          }
          chartData.name = item.platform_name
          chartOption.legend.data = []
          chartOption.legend.data.push(item.platform_name)
          for (let i = 1; i <= 12; i++) {
            if (item[`month_${i}`]) {
              chartData.data.push(item[`month_${i}`])
            } else {
              chartData.data.push(0)
            }
          }
          newArr.push(chartData)
        })
        chartOption.series = newArr
        myChart.setOption(chartOption)
      }
    }
  },
  mounted() {
    this.getChartDataRevenue()
  },
  update() {
    this.getChartDataRevenue()

  },
  beforeDestroy() {
    let myChart = echarts.init(this.$refs.bar_dv)
    myChart.clear()
  }
}
</script>

<style scoped>


</style>
