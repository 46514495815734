<template>
  <div>
    <div class='title-row'>
      <span class='title' @click='initList'>涨粉情况一览-{{ searchCondition.year || '' }}年 </span>
      <el-date-picker
        style='width: 120px'
        clearable
        format='yyyy年'
        placeholder='选择年'
        type='year'
        v-model='searchCondition.year' value-format='yyyy' @change='handleQuery'>
      </el-date-picker>
      &nbsp;
      <el-select v-model='searchCondition.artist_id' placeholder='组内红人' style='width: 220px' @change='handleQuery'
                 clearable filterable>
        <el-option :label='`${kol.nickname}`' :value='`${kol.id}`' v-for='kol in kolOptions' :key='kol.id'>
          <span style='float: left'>{{ kol.nickname }}</span>
          <span style='float: right;font-weight: lighter'>『{{ kol.sign_status === 'Y' ? '在约' : '签约' }}』</span>
        </el-option>
      </el-select>
      <el-button @click='loadKolOptions' icon='el-icon-refresh' type='text' title='点击刷新红人选项'></el-button>
    </div>
    <div class='default-table'>
      <ape-table show-summary	 :columns='columns' :data='dataList' :loading='loadingStatus' max-height='500' border>
      </ape-table>
    </div>
    <!--      图表-->
        <LineChart ref='refTrendMonthDataChart' :dataList='dataList' :title="'涨粉'" v-if='isHiddenbtn'></LineChart>

  </div>
</template>

<script>
import ApeTable from '../components/ApeTable'
import LineChart from '../components/LineChart'
import GroupSelect from '@/pages/team/components/GroupSelect'

export default {
  name: 'TrendMonthlyData',
  components: { ApeTable, LineChart, GroupSelect },

  props: {
    condition: {
      type: Object,
      default: () => ({ year: null, group_id: null, group_name: '', quarter: 0, release_status: 'Y' })
    },
    isHiddenbtn: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    condition: {
      immediate: true,
      deep: true,
      handler(val) {
        this.searchCondition.year = val.year
        this.searchCondition.group_id = val.group_id
        this.loadKolOptions()
        this.initList()
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      columns: [],
      groupId: '',
      searchCondition: { year: null, group_id: null, artist_id: null },
      kolOptions: []
    }
  },
  methods: {
    //该组当前的红人
    async loadKolOptions() {
      if (this.searchCondition.group_id) {
        let { list } = await this.$api.loadGroupArtists(this.searchCondition.group_id)
        this.kolOptions = list
      }
    },
    async initList() {
      this.loadingStatus = true
      this.dataList = []
      if (this.searchCondition && this.searchCondition.year && this.searchCondition.group_id) {
        let data = await this.$api.getPerformanceRrendGroupList(this.searchCondition)
        this.$nextTick(() => {
          this.dataList = data
          this.loadingStatus = false
        })
      }
    },
    handleQuery() {

      this.initList()
    }
  },
  created() {
    this.columns = [{
      value: 'platform_name',
      title: '平台',
      width: 120,
      align: 'center'
    }]
    for (let i = 1; i <= 12; i++) {
      this.columns.push({
        value: `month_${i}`,
        title: `${i}月`,
        width: 120,
        header_align: 'center',
        align: 'right'
      })
    }
    this.columns.push({
      value: 'total',
      title: '汇总',
      width: 120,
      header_align: 'center',
      align: 'right'
    })
    // this.initList()
    // this.getGroup()

  }
}
</script>

<style scoped>
.title-row {
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  font-weight: 800;
  float: left;
  line-height: 30px;
  padding-right: 20px;
}
</style>
