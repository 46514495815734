<template>
  <div>
<!--    <span class='title'>{{ searchCondition.year || '' }}年『{{ searchCondition.group_name || '' }}』 </span>-->
    <div v-if="userPermissions.indexOf('team_kol_situation') !== -1">
      <sign-monthly-list ref='refTeamKolSituation' :search-condition='searchCondition' v-hasAuthority="'team_kol_situation'"/>
      <br />
      <div v-if="userPermissions.indexOf('team_kol_track') !== -1">
        <sign-track-list ref='refTeamKolChange'  :search-condition='searchCondition'   v-hasAuthority="'team_kol_track'"/>
      </div>
    </div>
  </div>
</template>

<script>
import SignMonthlyList from '@/pages/team/artist/SignMonthlyList'
import SignTrackList from '@/pages/team/artist/SignTrackList'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamArtistReview',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: { SignTrackList, SignMonthlyList },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  data() {
    return {
      // searchCondition: { year: null, group_id: null }
    }
  },
  methods: {
    init(){

      this.$nextTick(()=>{
        this.$refs['refTeamKolSituation'].init()
        this.$refs['refTeamKolChange'].init()
      })
    },
    selectDept(val) {
      this.searchCondition.group_id = val && val.length === 2 ? val[val.length - 1] : null
    }
  },
  created() {
    this.searchCondition.year = '' + (new Date()).getFullYear()
  }
}
</script>

<style scoped>
.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
