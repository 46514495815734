<template>
  <div>
    <el-link @click='initList' style='padding: 10px 0' type='primary'>涨粉绩效目标<span
      style='color: #1a1a1a;'>与完成情况</span></el-link>
    <div class='default-table'>
      <el-table
        :cell-class-name='cellClassName'
        :data='tblData'
        :span-method='objectSpanMethod'
        border
        style='width: 100%'>
        <el-table-column
          align='center'
          label='红人昵称'
          min-width='110'
          prop='nickname'>
          <template slot-scope='{row}'>
            <artist-nickname :info='row' />
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='平台'
          min-width='100'
          prop='platform_name'>
        </el-table-column>
        <el-table-column
          align='center'
          header-align='center'
          label='上季月涨粉均值' min-width='100' prop='average_value'>
          <template slot-scope='scope'>
            <span v-if='scope.$index<tblData.length-1'>{{ valFormat(scope.row[`average_value`]) }}</span>
            <span v-else>{{ (scope.row[`average_value`]) }}</span>
          </template>
        </el-table-column>
        <el-table-column :key='index' :label='`${month}月`' align='center'
                         v-for='(month,index) in quarterlyMonths'>
          <el-table-column
            :prop='`quotient_${month}`'
            align='center'
            header-align='center' label='系数' min-width='100'>
            <template slot-scope='{row,$index}'>
                    <span :class='calcBonusClass(row[`amount_${ month }`])' v-if='$index===tblData.length-1'>{{
                        moneyFormat(row[`amount_${month}`])
                      }}</span>
              <span v-else>{{ row[`quotient_${month}`] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :prop='`flag_value_${month}`'
            align='center'
            header-align='center' label='目标' min-width='100'>
            <template slot-scope='{row,$index}'>
                    <span :class='calcBonusClass(row[`amount_${ month }`])' v-if='$index===tblData.length-1'>{{
                        moneyFormat(row[`amount_${month}`])
                      }}</span>
              <span v-else>{{ valFormat(row[`flag_value_${month}`]) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :prop='`real_value_${month}`'
            align='center'
            header-align='center' label='完成' min-width='100'>
            <template slot-scope='{row,$index}'>
                    <span :class='calcBonusClass(row[`amount_${ month }`])' v-if='$index===tblData.length-1'>{{
                        moneyFormat(row[`amount_${month}`])
                      }}</span>
              <span v-else>{{ valFormat(row[`real_value_${month}`]) }}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PerformanceTrend',
  props: {
    searchCondition: {
      type: Object,
      default: () => ({ year: null, group_id: null, group_name: '', quarter: 0, })
    }
  },
  computed: {
    quarterlyMonths() {
      let quarter = this.searchCondition.quarter
      return [1 + (quarter - 1) * 3, 2 + (quarter - 1) * 3, 3 + (quarter - 1) * 3]
    },
    tblData() {
      if (this.dataList.length > 0) {
        let calcRow = this.calcCol()

        //合计数据-初始化
        let appendRows = [{ nickname: '本组总涨粉', platform_name: '本组总涨粉', ...calcRow }]
        appendRows.push({ ...this.bonusRow })

        return [...this.dataList, ...appendRows]
      }

      return []
    }
  },
  data() {
    return {
      loadingStatus: false,
      dataList: [],
      bonus_items: [],//团队奖金
      calcFields: [],//本组总涨粉行的计算字段
      bonusRow: { nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金' },//团队奖金行的可编辑列
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      checkMonthList: []//核算完成的月份
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        this.initList()
      }
    }
  },
  methods: {
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_')
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''

    },

    async initList() {
      //计算的列
      this.getCalcFields()
      //当前已核算完成的月份
      // await this.getCheckMonths()
      //重置数据
      this.dataList = []
      this.loadingStatus = true
      if (this.searchCondition && this.searchCondition.year && this.searchCondition.quarter && this.searchCondition.group_id) {
        let { list, bonus_items } = await this.$api.getPerformanceQuarterTrend({
          'quarter': this.searchCondition.quarter,
          'year': this.searchCondition.year,
          'group_id': this.searchCondition.group_id
        })
        this.bonus_items = bonus_items || 0
        if (this.bonus_items.length > 0) {
          let row = {}
          this.bonus_items.forEach((item) => {
            row[`amount_${item.month}`] = item.amount
          })
          this.bonusRow = { nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金', ...row }
        }
        this.$nextTick(() => {
          this.dataList = list
          this.loadingStatus = false
          this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
        })
      }
    },
    // async getCheckMonths() {
    //   let { months } = await this.$api.getPerformanceCheckedMonths({
    //     type: 'revenue',
    //     group_id: this.searchCondition.group_id,
    //     year: this.searchCondition.year,
    //     months: this.quarterlyMonths
    //   })
    //   //当前已核算完成的月份
    //   this.checkMonthList = [...months]
    // },

    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.tblData.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.tblData[index][field] === this.tblData[index - 1][field]) {
              this.spanObj[field][this.positionObj[field]] += 1
              this.spanObj[field].push(0)
            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //末尾查询一行合计：
      if (rowIndex === this.tblData.length - 1) {
        // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
        if ((columnIndex + 1) % 3 === 0) {
          return [1, 3]
        } else {
          return [0, 0]
        }
      } else if (rowIndex === this.tblData.length - 2) {
        // 本组总涨粉：
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 2]
        } else {
          return [1, 1]
        }
      } else {
        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }

    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      this.bonusRow = { nickname: '团队奖金', platform_name: '团队奖金', average_value: '团队奖金' }
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化
          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    valFormat(value) {
      return this.$utils.numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    //计算列的合计
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1
              }
            })
          }
        })
      }
      return calcRow
    }
  },
  created() {
    this.initList()
  }
}
</script>

<style scoped>

</style>
