<template>
  <div>
    <div>
      <span class='title'>组内签约/解约情况明细</span>&nbsp;
      <el-button type='text' icon='el-icon-refresh' @click='handleSearch' :loading='loading'></el-button>
    </div>
    <div class='default-table' style='display: inline-block'>
      <el-table :data='dataList' max-height='600' @sort-change='changeTableSort' border>
        <el-table-column type='index' label='序号' width='60' align='center'></el-table-column>
        <el-table-column prop='nickname' label='红人昵称' width='140' align='center' sortable='custom'>
          <template slot-scope='{row}'>
            <artist-nickname :info='{artist_id:row.artist_id,nickname:row.nickname}'/>
          </template>
        </el-table-column>
        <el-table-column prop='sign_at' label='签约时间' width='140' align='center' sortable='custom'>
          <template slot-scope='{row,column}'>
            {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
          </template>
        </el-table-column>
        <el-table-column prop='in_at' label='调入时间' width='140' align='center' sortable='custom'>
          <template slot-scope='{row,column}'>
            {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
          </template>
        </el-table-column>
        <el-table-column prop='fired_at' label='解约时间' width='140' align='center' sortable='custom'>
          <template slot-scope='{row,column}'>
            {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
          </template>
        </el-table-column>
        <el-table-column prop='out_at' label='调出时间' width='140' align='center' sortable='custom'>
          <template slot-scope='{row,column}'>
            {{ $utils.parseTime(row[column.property], '{y}-{m}-{d}') }}
          </template>
        </el-table-column>
        <el-table-column label='运营时长' width='160' align='center'>
          <template slot='header'>
            <span>运营时长（天）</span>
            <el-tooltip class='item' effect='dark' content='运营时长=解约时间/调出时间-签约时间-调入时间' placement='right'>
              <i class='el-icon-question'></i>
            </el-tooltip>
          </template>
          <template slot-scope='{row}'>{{ row.duration || '/' }} 天</template>
        </el-table-column>
        <el-table-column label='备注' prop='remark' show-overflow-tooltip width='400'>

        </el-table-column>
      </el-table>
      <div>
        <pagination :total.sync='total' :limit.sync='pageData.page_size'
                    :page.sync='pageData.current_page'
                    @pagination='getList' />
      </div>
    </div>
    <div style='display: inline-block;text-align: left;vertical-align: top;margin-left: 5px'>
      <i class='el-icon-info'></i>按照红人归属该组的时间进行排序，越早的红人排在前
<!--      <div>-->
<!--        {{ orderSort }}-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'

export default {
  name: 'SignTrackList',
  components: { ApeTable },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  data() {
    return {
      loading: false,
      dataList: [],
      orderSort: { 'in_at': 'asc'},
      pageData: { page_size: 15, current_page: 1 },
      total: 0
    }
  },
  watch: {
    searchCondition: {
      immediate: true,
      deep: true,
      handler() {
        this.handleSearch()
      }
    }
  },
  methods: {
    init() {
      this.handleSearch()
    },
    changeTableSort({ prop, order }) {
      let orderSort = { ...this.orderSort }
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()
    },
    handleSearch() {
      // this.orderSort = {}
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.dataList = []
      // this.$notify.info(`${this.searchCondition.year}年，组ID：${this.searchCondition.group_id}`)
      if (this.searchCondition.year && this.searchCondition.group_id) {
        this.loading = true
        let {
          list,
          pages
        } = await this.$api.getTeamKoLChangeList(Object.assign(this.searchCondition, this.pageData, { order: this.orderSort }))
        this.$nextTick(() => {
          this.dataList = list || []
          this.total = pages.total || 0
          this.pageData.current_page = pages.current_page || 1
          this.pageData.page_size = pages.page_size || 15
        })

        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    }
  }
}
</script>

<style scoped>
.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
