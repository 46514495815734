<template>
  <div>
    <div>
      <span class='title' :title='`1）入组在指定月份月底之前 2)调出时间在本月月初之后或者没有调出`'>组织人效一览</span>
      <el-button type='text' :loading='loading' :disabled='loading' @click='getList'
                 icon='el-icon-refresh'></el-button>
    </div>
    <div class='default-table'>
      <el-table :data='tableData' @cell-click='showDetail' border>
        <el-table-column prop='type' align='center'>
          <template slot='header'>
            <span>月份</span>
            <el-tooltip class='item' effect='dark' content='人效=总营收/团队人数' placement='right'>
              <i class='el-icon-question'></i>
            </el-tooltip>
          </template>
          <template slot-scope='scope'>
            <span class='th'>{{ scope.row.type_alisa }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for='(v,k) in columns'
          :key='k'
          :prop="typeof(v.value)=='string'?v.value:''"
          :label='v.title'
          :min-width='v.width'
          :show-overflow-tooltip='true'
          :header-align="v.header_align?v.header_align:'center'"
          :align="v.align?v.align:'center'"
          :sortable='v.sortable?v.sortable:false'>
          <template slot-scope='{row,column}'>
            <el-link type='primary' title='查看团队人数详情' v-if='row.field==="num"'>{{ row[column.property] }}</el-link>
            <!--            <el-link v-else-if='row.field==="revenue_total"'>{{ moneyFmt(row[column.property]) }}</el-link>-->
            <span v-else>{{ moneyFmt(row[column.property]) }}</span>
          </template>
        </el-table-column>
      </el-table>
<!--      <div style='display: inline-block;text-align: left;vertical-align: middle'>-->
<!--        &nbsp;&nbsp;<i class='el-icon-info'></i>&nbsp;&nbsp;{{ `1）入组在指定月份月底之前 2）调出时间在本月月初之后或者没有调出 3）同一个人多次进出该组计1次人数` }}-->
<!--      </div>-->
    </div>

    <!--    团队详情-->
    <el-dialog :title='dialogTitle' :visible.sync='dialogTableVisible'>
      <div>
        <el-card>
          本月在职成员：
          <el-tag effect='plain' v-for='(member,index) in onJobMembers' :key='index'>{{ index + 1 }}）{{ member.name }}&nbsp;</el-tag>
        </el-card>
      </div>
      <el-table :data='gridData' max-height='400'>
        <el-table-column type='index' label='序号' width='60' align='center'></el-table-column>
        <el-table-column label='岗位' prop='position' align='center' sortable></el-table-column>
        <el-table-column label='姓名' prop='name' align='center' sortable>
        </el-table-column>
        <el-table-column label='调入时间' prop='in_at' align='center' sortable>
        </el-table-column>
        <el-table-column label='入职时间' prop='hired_date' align='center' sortable>
          <template slot-scope='{row}'>
            {{ row.hired_date === '1970-01-01' ? '' : row.hired_date }}
          </template>
        </el-table-column>
        <el-table-column label='离职时间' prop='leave_date' align='center' sortable></el-table-column>
        <el-table-column label='调动时间' prop='out_at' align='center' sortable></el-table-column>
        <el-table-column label='调动部门' prop='out_dept_name' align='center' sortable></el-table-column>
      </el-table>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'EffectList',
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, group_id: null }
      }
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      dialogTitle: '',
      gridData: [],
      loading: false,
      columns: [],
      dataList: [
        { type_alisa: '总营收' },
        { type_alisa: '团队人数' },
        { type_alisa: '人效' }
      ]
    }
  },
  computed: {
    tableData() {
      let rows = [
        { field: 'revenue_total', type_alisa: '总营收' },
        { field: 'num', type_alisa: '团队人数' },
        { field: 'per_revenue', type_alisa: '人效' }
      ]
      rows.forEach(row => {
        let field = row.field
        for (let i = 1; i <= 12; i++) {
          row[`month_${i}`] = null
          let info = this.dataList.find(value => value.month === i)
          if (info) {
            row[`month_${i}`] = info[field]
          }
        }
      })

      return rows
    },
    onJobMembers() {
      let members = []
      this.gridData.forEach((item) => {
        let index = members.findIndex(value => value.userid === item.userid)
        if (index === -1)
          members.push(item)
      })
      return members
    }
  },
  watch: {
    searchCondition: {
      immediate: true,
      deep: true,
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    moneyFmt(val) {
      return this.$utils.numberFormat(val, 2, '.', ',', 'round')
    },
    async getList() {
      if (this.searchCondition.group_id && this.searchCondition.year) {
        let { list } = await this.$api.getTeamMemberEfficiencyData({
          dept_id: this.searchCondition.group_id,
          year: this.searchCondition.year
        })
        // console.log(list)
        this.dataList = [...list]
      }
    },
    // eslint-disable-next-line no-unused-vars
    async showDetail(row, column, cell, event) {
      //
      event.preventDefault()
      if (row.field === 'num' && column.property.indexOf('month_') > -1) {
        let monthArr = column.property.split('_')
        let month = monthArr.length === 2 ? monthArr[1] : null
        let year = this.searchCondition.year
        let dept_id = this.searchCondition.group_id
        if (year && month && dept_id) {
          this.dialogTitle = `${year}年${month}月在职人员详情`
          let { list } = await this.$api.getTeamMemberViewDetail({
            year: year,
            month: month,
            dept_id: dept_id
          })
          this.gridData = [...list]
          this.dialogTableVisible = true

        } else {
          this.$notify.warning(`参数异常：【${dept_id}】${year}年${month}月`)
        }
      }
    }
  },
  created() {
    // 表列名计算
    this.columns = []
    for (let i = 1; i <= 12; i++) {
      this.columns.push({
        value: `month_${i}`,
        title: `${i}月`,
        width: 120,
        header_align: 'center',
        align: 'right'
      })
    }
    // getList()
  }
}
</script>

<style scoped>
.th {
  line-height: 30px !important;
  font-size: 14px !important;
  font-weight: 800 !important;
  color: #0C0C0C !important;
}

.title-row {
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  font-weight: 800;
}
</style>
