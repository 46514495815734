<template>
  <div>
    <el-dialog title='成员信息实时数据（数据来自钉钉）' width='1000px' v-bind='$attrs' v-on='$listeners' @open='onOpen'
               @close='onClose'>
      <div>
        <div v-if='memberInfo.ding_user_info'>
          <employee-info :groupId='groupId' :user-info='memberInfo.ding_user_info'
                         :team-member-id='memberInfo.id' />
          <!--          <EmployeeInfo :groupId='searchCondition.group_id' :user-info='memberInfo.ding_user_info' :team-member-id='memberInfo.id' @saved='getList' />-->
        </div>
        <br />
        <div v-if='memberInfo.leave_info'>
          <div>
            <leave-employee-info :info='memberInfo.leave_info' />
            <!--            <LeaveEmployeeInfo :info='memberInfo.leave_info' />-->
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import EmployeeInfo from '@/pages/team/dept/EmployeeInfo'
import LeaveEmployeeInfo from '@/pages/team/dept/LeaveEmployeeInfo'

export default {
  name: 'MemberDetail',
  components: { LeaveEmployeeInfo, EmployeeInfo },
  props: {
    memberId: {
      type: [Number, String],
      default() {
        return null
      }
    },
    groupId: {
      type: [Number, String],
      default() {
        return null
      }
    }
  },
  watch: {
    memberId: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        // console.log(val, oldVal)
        this.getTeamMemberInfo()
      }
    }
  },
  data() {
    return {
      memberInfo: {}
    }
  },
  methods: {
    onOpen() {
      this.getTeamMemberInfo()
    },
    onClose() {
      this.$emit('saved')
    },
    close() {
      this.$emit('update:visible', false)
    },
    async getTeamMemberInfo() {
      this.memberInfo = {}
      if (this.memberId) {
        let data = await this.$api.syncTeamMember({ id: this.memberId })
        // this.memberInfo = { ...data, ...data.leave_info, ...data.err_msg }
        this.memberInfo = { ...data }
      }

    }
  }
}
</script>

<style scoped>

</style>