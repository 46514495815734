<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <div>
          <el-row :gutter="12">
            <el-col :lg="12" :md="24" :sm="24">
              <el-card shadow="hover" class="box-card">
                <el-link type="primary" @click="pageChildren('enter')">&nbsp;&nbsp;店铺数据-录入&nbsp;&nbsp;</el-link>
              </el-card>
            </el-col>
            <el-col :lg="12" :md="24" :sm="24">
              <el-card shadow="hover" class="box-card">
                <el-link type="success" @click="pageChildren('data')">&nbsp;&nbsp;店铺数据-列表&nbsp;&nbsp;</el-link>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>

import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import { mapGetters } from 'vuex'

export default {
  name: "shopDataIndex",
  components: {
    PageHeaderLayout,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  watch: {},
  methods: {
    // 数据录入
    pageChildren(route) {
      this.$router.push(this.$route.path + '/' + route)
    },
  },

  mounted() {
  },
}
</script>

<style scoped>
.box-card {
  margin-bottom: 10px;
}

</style>
