<template>
  <div>
    <el-form :model="searchCondition" :inline="true" size="mini" style="padding-left: 10px">
      <el-form-item label="">
        <el-date-picker
            v-model="searchCondition.year"
            type="year"
            format="yyyy"
            value-format="yyyy"
            placeholder="选择年度" style="width: 120px">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-popover
            placement="right"
            title=""
            width="300"
            trigger="click"
        >
          <el-button size="mini" type="default" slot="reference">
            选择季度<span v-if="searchCondition.quarter">: 第 {{ searchCondition.quarter }} 季度</span>
          </el-button>
          <el-row>
            <el-col :span="24">
              <el-radio-group v-model="searchCondition.quarter">
                <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                  {{ quarter.label }}
                </el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
        </el-popover>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "SearchCommon",
  data() {
    return {
      searchCondition: { year: null, quarter: 1 },
      quarters: [
        // { label: '未选季度', value: 0 },
        { label: '第1季度', value: 1 },
        { label: '第2季度', value: 2 },
        { label: '第3季度', value: 3 },
        { label: '第4季度', value: 4 },
      ],
    }
  },
  watch: {
    'searchCondition.year'(val) {
      this.$emit('changeCondition', 'year', val)
    },
    'searchCondition.quarter'(val) {
      this.$emit('changeCondition', 'quarter', val)
    },
  },
  created() {
    this.searchCondition.year = (new Date()).getFullYear() + ''
    // 获取当前季度：
    let currMonth = new Date().getMonth() + 1;
    this.searchCondition.quarter = Math.floor((currMonth % 3 === 0 ? (currMonth / 3) : (currMonth / 3 + 1)));
  },

}
</script>

<style scoped>

</style>
