<template>
  <div>
    <el-card>
      <div></div>
      <div slot='header' class='clearfix'>
        <span>在职成员信息（{{ teamMemberId }}）</span>
        <el-link href='https://open.dingtalk.com/document/isvapp/query-user-details' target='_blank'>查询用户详情
        </el-link>
        <el-button size='mini' style='float: right' type='danger' @click='departBtn'>离职</el-button>
      </div>
      <div>
        <el-row>
          <el-col>
            <el-avatar :src='userInfo.avatar'></el-avatar>
          </el-col>
        </el-row>
        <el-descriptions title=''>
          <el-descriptions-item label='姓名'>{{ userInfo.name }}</el-descriptions-item>
          <el-descriptions-item label='员工工号'>{{ userInfo.job_number }}</el-descriptions-item>
          <el-descriptions-item label='手机号'>{{ userInfo.mobile }}</el-descriptions-item>
          <el-descriptions-item label='职位'>{{ userInfo.title }}</el-descriptions-item>
          <el-descriptions-item label='员工邮箱'>{{ userInfo.email }}</el-descriptions-item>
          <el-descriptions-item label='员工的企业邮箱'>{{ userInfo.org_email }}</el-descriptions-item>
          <el-descriptions-item label='入职时间'>
            <el-date-picker
              style='width: 160px'
              v-model=' userInfo.hired_at'
              type='date'
              format='yyyy年MM月dd日'
              value-format='yyyy-MM-dd'
              placeholder='选择日期'>
            </el-date-picker>
            &nbsp;&nbsp;<el-link type='primary' icon='el-icon-refresh' @click='saveInfo'>更新</el-link>
          </el-descriptions-item>
          <el-descriptions-item label='备注'>{{ userInfo.remark }}</el-descriptions-item>
          <el-descriptions-item label='办公地点'>{{ userInfo.work_place }}</el-descriptions-item>
        </el-descriptions>
        <el-divider posiiton='center'>钉钉原始数据
          <el-link type='primary' @click='showOrgData'><i
            :class="[this.showOrgView?'el-icon-arrow-up':'el-icon-arrow-down']"></i></el-link>
        </el-divider>
        <div v-show='this.showOrgView'>
          {{ userInfo }}
        </div>
      </div>
    </el-card>

    <!--    离职 操作-->
    <set-employee-leave  :team-member-id='teamMemberId' :visible.sync='dialogDepart' />

    <!--    <el-dialog title='请填写离职时间' width='20%' :modal-append-to-body='false' :visible.sync='dialogDepart'>-->
    <!--      <el-date-picker-->
    <!--        @change='batchdepart'-->
    <!--        style='text-align: center;margin: 0 auto'-->
    <!--        v-model='departTime'-->
    <!--        type='date'-->
    <!--        value-format='yyyy-MM-DD'-->
    <!--        placeholder='选择日期'>-->
    <!--      </el-date-picker>-->
    <!--    </el-dialog>-->

  </div>
</template>

<script>
import SetEmployeeLeave from '@/pages/team/dept/SetEmployeeLeave'

export default {
  name: 'EmployeeInfo',
  components: { SetEmployeeLeave },
  props: {
    userInfo: {
      type: Object,
      default() {
        return {}
      }
    },
    groupId: {
      type: String
    },
    teamMemberId: {
      type: [Number, String],
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      dialogDepart: false,
      departTime: '',
      showOrgView: false
    }
  },
  methods: {
    showOrgData() {
      this.showOrgView = !this.showOrgView
    },
    // async batchdepart() {
    //   let userData = {
    //     userid: this.userInfo.userid,
    //     dept_id: this.groupId,
    //     nick: this.userInfo.name,
    //     leave_date: this.departTime
    //   }
    //   this.dialogDepart = false
    //   this.$confirm(
    //     `确定离职【${this.userInfo.name}】？`,
    //     '提示',
    //     {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(async () => {
    //     let data = await this.$api.departTeamMemberUser({ items: [userData] })
    //     if (data) {
    //       if (data.errs.length != 0) {
    //         this.$message.warning(`离职失败,原因：${data.errs[0].msg}`)
    //       } else {
    //         this.$message.success(`离职成功`)
    //         this.$emit('closeInfo')
    //
    //       }
    //     }
    //   })
    //
    // },

    departBtn() {
      this.dialogDepart = true

    },
    async updateMemberInfo(params) {
      let info = await this.$api.departTeamRefreshUser(params)
      if (info) {
        this.$message({
          type: 'success',
          message: '成功!'
        })
        this.$emit('saved')
      }
    },
    saveInfo() {
      let params = { id: this.teamMemberId, hired_date: this.userInfo.hired_at }
      this.$confirm(`确认修改入职时间为"${params.hired_date}"`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.updateMemberInfo(params)

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
