<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <div>
          <el-row :gutter="12">
            <el-col :span="8">
              <el-card shadow="hover">
                <el-link type="primary" @click="pageChildren('enter')">&nbsp;&nbsp;涨粉数据-录入&nbsp;&nbsp;</el-link>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover">
                <el-link type="success" @click="pageChildren('data')">&nbsp;&nbsp;涨粉数据-列表&nbsp;&nbsp;</el-link>
              </el-card>
            </el-col>
            <el-col :span="8">
              <el-card shadow="hover">
                <el-link type="warning" @click="pageJump('trend_data')">&nbsp;&nbsp;涨粉数据-汇总&nbsp;&nbsp;</el-link>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>

import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import {mapGetters} from 'vuex'

export default {
  name:'TrendIndex',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  watch: {},
  methods: {
    // 数据录入
    pageChildren(route) {
      this.$router.push(this.$route.path + '/' + route)
    },
    pageJump(route) {
      this.$router.push('/' + route)
    }
  },

  mounted() {
  },
}
</script>

<style lang="stylus">
</style>
