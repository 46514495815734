<template>
  <div>
    <el-tabs class='performance-tab' tab-position='left' v-model='activeName'>
      <el-tab-pane label='涨粉绩效目标' name='trend'>
        <PerformanceTrend :searchCondition='searchCondition' ref='refPerformanceTrend' v-bind='$attrs' />
      </el-tab-pane>
      <el-tab-pane label='营收绩效目标' name='revenue'>
        <PerformanceRevenue :searchCondition='searchCondition' ref='refPerformanceRevenue' v-bind='$attrs' />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PerformanceRevenue from './PerformanceRevenue'
import PerformanceTrend from './PerformanceTrend'
import SearchCommon from './SearchCommon'
import GroupSelect from '../components/GroupSelect'

export default {
  name: 'PerformanceData',
  components: { SearchCommon, PerformanceTrend, PerformanceRevenue, GroupSelect },
  props: {
    searchCondition: {
      type: Object,
      default: () => ({ year: null, group_id: null, group_name: '', quarter: 0, release_status: 'Y' })
    }
  },
  data() {
    return {
      activeName: 'trend',
      currentGroupOptions: [],
      groupId: '',
      SearchData: {}
    }
  },
  // watch: {
  //   searchCondition: {
  //     deep: true,
  //     handler() {
  //       this.getGroupList()
  //     }
  //   }
  // },
  methods: {
    async getGroupList() {
      this.loadingStatus = true
      let SearchData = this.handleSearch()
      if (SearchData && SearchData.year) {
        let { list } = await this.$api.getGroupListOfPerformanceRevenue(SearchData)
        //revenueDatumList
        this.$nextTick(() => {
          this.currentGroupOptions = list
          this.loadingStatus = false
        })
      }
    },

    handleQuery() {
      // this.initList()
    },
    handleSearch() {
      let SearchData = { ...this.searchCondition }
      SearchData.group_id = this.groupId
      return SearchData

    }
  }

}
</script>

<style scoped>
.title-row {
  padding-left: 10px;
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  font-weight: 800;
  float: left;
  line-height: 30px;
  padding-right: 20px;
}
</style>
